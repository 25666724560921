
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageSynopsis extends Vue {
  // tab选中项
  private active = 1
  mounted() {
    switch (this.$route.path) {
      case '/synopsis/about':
        this.active = 1
        break
      case '/synopsis/achievement':
        this.active = 2
        break
      case '/synopsis/concat':
        this.active = 3
        break

      default:
        break
    }
    this.init()
  }

  private async init() {}
  /**
   * @Description: 切换tab
   * @params {*}
   * @return {*}
   */
  private handlerTab(num: number, path: string) {
    this.active = num
    this.$router.push({
      path
    })
  }
}
