
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { timeFormat } from '../../utils/comUtils'
import { Pagination } from 'element-ui'

@Component({
  components: {
    Pagination
  }
})
export default class PageNewsList extends Vue {
  // tabs切换：1-魔点动态，2-媒体报道
  private activeType = 1
  // 当前页
  private currentPage = 1
  // 每页条数
  private pageSize = 10
  // 总条数
  private totalCount = 0
  // 新闻列表
  private newsList = []

  @Watch('activeType')
  watchActiveType() {
    this.init()
  }
  mounted() {
    this.init()
  }

  private async init() {
    this.currentPage = 1
    const res = await this.fetchGetNewsList()
    this.newsList = res.data.data || []
    this.totalCount = res.data.totalCount || 0
  }

  /**
   * @Description: 进入详情页
   * @params {*}
   * @return {*}
   */
  private handlerItem(data: any) {
    if (this.activeType === 1) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: data.id
        }
      })
    } else {
      window.open(data.detailContentUrl)
    }
  }

  /**
   * @Description: 切换分页
   * @params {*}
   * @return {*}
   * @param {*} index
   */
  private async handleCurrentChange(index: number) {
    this.currentPage = index
    const res = await this.fetchGetNewsList()
    this.totalCount = res.data.totalCount || 0
    this.newsList = res.data.data || []
    window.scroll(0, (this.$refs['banner'] as any).offsetHeight)
  }

  /**
   * @Description: 切换tab
   * @params {*}
   * @return {*}
   */
  private handlerTab(activeType: number) {
    this.activeType = activeType
  }

  /**
   * @Description: 获取列表数据
   * @params {*}
   * @return {*}
   */
  private fetchGetNewsList() {
    return this.$get('getNewsList', { dynamicType: this.activeType, pageSize: this.pageSize, pageNo: this.currentPage })
  }

  /**
   * @Description: 时间格式化
   * @params {*}
   * @return {*}
   * @param {*} date
   */
  private methodTimeFormat(date: number) {
    return timeFormat(new Date(date))
  }
}
