
import 'swiper/dist/css/swiper.css'
require('md-ui-pc/lib/md-ui-pc.css')
require('@/assets/css/animate.scss')
require('@/assets/css/common.scss')
require('@/assets/css/icon.css')
import { Component, Vue } from 'vue-property-decorator'
import WapTopNav from '@/components/WapTopNav.vue'
import WapBottomNav from '@/components/WapBottomNav.vue'

@Component({
  components: {
    WapTopNav,
    WapBottomNav
  }
})
export default class App extends Vue {}
