
import showToast from '../../components/Toast/index'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageParticipate extends Vue {
  private postData = {
    contactPersonName: '',
    phone: '',
    contactName: '',
    email: '',
    city: '',
    counselType: 3
  }
  // 防重复提交
  private isCanSave = true
  // 手机号是否为空
  private isMobileEmpty = false
  // 手机号是否错误
  private isErrorMobile = false
  // 邮箱是否错误
  private isErrorEmail = false
  mounted() {
    this.init()
  }

  private async init() {}
  /**
   * @Description: 提交
   * @params {*}
   * @return {*}
   */
  handleSave() {
    // 防止多次点击
    if (!this.isCanSave) {
      return
    }
    // 重置错误信息
    this.isMobileEmpty = false
    this.isErrorMobile = false
    this.isErrorEmail = false
    const _phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    const _emailReg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    // 判断手机号非空
    if (this.postData.phone === '') {
      return (this.isMobileEmpty = true)
    }
    // 校验手机号
    if (!_phoneReg.test(this.postData.phone)) {
      this.isErrorMobile = true
    }
    // 校验邮箱
    if (!_emailReg.test(this.postData.email) && !!this.postData.email) {
      this.isErrorEmail = true
    }
    // 校验都通过继续
    if (this.isErrorMobile || this.isErrorEmail) {
      return
    }
    this.isCanSave = false
    this.$post('postContact', this.postData)
      .then(() => {
        this.isCanSave = true
        this.postData = {
          contactPersonName: '',
          phone: '',
          contactName: '',
          email: '',
          city: '',
          counselType: 3
        }
        showToast({
          message: '提交成功',
          type: 'success'
        })
      })
      .catch(() => {
        this.isCanSave = true
        showToast({
          message: '服务器开小差了',
          type: 'error'
        })
      })
  }
}
