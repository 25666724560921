var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pageConcat"},[_c('div',{staticClass:"one"},[_c('el-amap',{attrs:{"vid":'amap-vue',"zooms":[3, 19],"zoom":16,"center":[120.017416, 30.286023]}},[_c('el-amap-marker',{attrs:{"position":[120.017416, 30.286023]}})],1)],1),_vm._m(0),_c('div',{staticClass:"three m-p-120-0 m-p-0-30"},[_vm._m(1),_c('div',{staticClass:"m-m-0-30 m-font-32 m-mt-20"},[_vm._v("点击左侧按钮扫描相应二维码或前往相关魔点主页")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === 1),expression:"activeIndex === 1"}],staticClass:"item m-p-80-0 m-p-0-30 m-fb m-fb-main-between"},[_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === 2),expression:"activeIndex === 2"}],staticClass:"item m-p-80-0 m-p-0-30 m-fb m-fb-main-between"},[_vm._m(4)]),_c('div',{staticClass:"tabs m-fb m-fb-main-around"},[_c('div',{staticClass:"m-mt-60 m-relative",class:{ active: _vm.activeIndex === 1 },on:{"click":function($event){return _vm.handlerTab(1)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === 1),expression:"activeIndex === 1"}],staticClass:"arrow"}),_c('i',{staticClass:"m-icon m-weixin m-font-80"})]),_vm._m(5),_c('div',{staticClass:"m-mt-60 m-relative",class:{ active: _vm.activeIndex === 2 },on:{"click":function($event){return _vm.handlerTab(2)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === 2),expression:"activeIndex === 2"}],staticClass:"arrow"}),_c('i',{staticClass:"m-icon m-douyin m-font-80"})]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"two m-p-0-60 m-pb-60 m-font-32"},[_c('div',{staticClass:"m-mt-60 m-fb m-fb-cross-center"},[_c('i',{staticClass:"m-icon m-dizhi m-mr-10"}),_vm._v("杭州总部")]),_c('div',{staticClass:"m-c-9A9A9A"},[_vm._v("杭州市余杭区高教路959号绿城未来Park3幢6层")]),_c('div',{staticClass:"m-mt-40 m-fb m-fb-cross-center"},[_c('i',{staticClass:"m-icon m-shouhoufuwu m-mr-10"}),_vm._v("售后服务")]),_c('div',{staticClass:"m-c-9A9A9A"},[_vm._v("400-033-7675")]),_c('div',{staticClass:"m-mt-40 m-fb m-fb-cross-center"},[_c('i',{staticClass:"m-icon m-shichanghezuo m-mr-10"}),_vm._v("市场合作")]),_c('div',{staticClass:"m-c-9A9A9A"},[_vm._v("marketing@moredian.com")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-m-0-30 m-font-48 m-bold"},[_c('div',[_vm._v("关注魔点")]),_c('div',[_vm._v("了解更多AI与场景")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"kind m-fb m-fb-col m-fb-main-between m-fb-cross-center"},[_c('img',{attrs:{"src":require("../../assets/synopsis/concat/二维码_魔点微信公众号.jpg"),"alt":""}}),_c('div',{staticClass:"m-font-32 m-bold"},[_vm._v("魔点微信公众号")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-fb m-fb-col m-fb-main-between m-fb-cross-center"},[_c('img',{attrs:{"src":require("../../assets/synopsis/concat/二维码_魔点微信视频号.png"),"alt":""}}),_c('div',{staticClass:"m-font-32 m-bold"},[_vm._v("魔点微信视频号")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-fb m-fb-col m-fb-main-between m-fb-cross-center"},[_c('img',{attrs:{"src":require("../../assets/synopsis/concat/二维码_魔点抖音.png"),"alt":""}}),_c('div',{staticClass:"m-font-32 m-bold"},[_vm._v("魔点抖音号")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-mt-60 m-relative"},[_c('a',{attrs:{"href":"https://weibo.com/u/6476627177","target":"_blank"}},[_c('i',{staticClass:"m-icon m-xinlang m-font-80"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-mt-60 m-relative"},[_c('a',{attrs:{"href":"https://space.bilibili.com/472133477?from=search&seid=10575320289426136136","target":"_blank"}},[_c('i',{staticClass:"m-icon m-bilibili m-font-80"})])])
}]

export { render, staticRenderFns }