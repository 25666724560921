
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component, Vue } from 'vue-property-decorator'
import WapConcatUs from '@/components/WapConcatUs.vue'

@Component({
  components: {
    WapConcatUs
  }
})
export default class PageSchool extends Vue {
  private options = {
    licenseKey: 'PageSchool',
    scrollOverflow: false
  }
  private isScroll = false

  private counselType = 5

  created() {
    if (window.screen.height <= 667) {
      this.isScroll = true
    }
  }
  mounted() {
    new Swiper('.swiper-container', {
      centeredSlides: true,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination'
      }
    })
    new Swiper('.swiper-container1', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination1'
      }
    })
    new Swiper('.swiper-container2', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination2'
      }
    })
    new Swiper('.swiper-container3', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination3'
      }
    })
    new Swiper('.swiper-container4', {})
  }
}
