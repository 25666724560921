
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PageAchievement extends Vue {
  // 当前年份定位
  private activeIndex = 2023
  private list = [
     {
      title: 2024,
      eventList: [
        '国家级专精特新“小巨人”企业',
        '浙江省 AA 级“守合同重信用”企业',
        '2024年度杭州市“市级商业秘密保护基地“',
        '“杭州市优秀版权作品”优秀奖',
        '2024年杭州市优质产品推荐目录',
        '余杭区数字经济“群英榜”年度数实融合典型场景',
        '制造业创新发展企业',
        '余杭区健康促进企业',
        '2024年杭州市准独角兽企业榜单'
      ]
    },
    {
      title: 2023,
      eventList: [
        '浙江省专精特新中小企业',
        '荣获杭州市专利试点企业称号',
        '浙江省产学研合作创新与促进奖',
        '与中科院、InsightFace联合举办CVPR全球活体检测挑战赛',
        '魔点AI实验室发布的论文被CVPRW2023所收录',
        '2023年5月荣获ECV2O23仪表OCR识别挑战赛亚军',
        '2023年9月荣获CFAT 2023人脸活体检测挑战赛冠军',
        '2023年9月荣获第四届CSIG图像图形技术挑战赛亚军',
        '发布“数字门迎”开启智能服务新模式',
        '杭州准独角兽企业榜单'
      ]
    },
    {
      title: 2022,
      eventList: [
        '浙江省高新技术企业研究开发中心',
        '2022年杭州市第一批重点建设人工智能应用场景',
        '中国信通院“可信人脸应用守护计划”成员单位',
        '魔点全球首创“无监督自学习”算法上线',
        '魔点 AI-M∞ST 空间多跨场景核心平台发布',
        '杭州准独角兽企业榜单',
        '2022年中国未来独角TOP100企业'
      ]
    },
    {
      title: 2021,
      eventList: [
        '杭州市企业技术中心',
        '成为杭州市重点扶持的上市企业',
        '2021“ICCV-全球戴口罩人脸识别大赛”全球前三',
        '成为IIFAA联盟成员，建立安全可信连接，探索场景多元道路',
        '2021年浙江省数字经济“五新”优秀案例',
        '2021年浙江省服贸发布百个服务示范案例',
        '杭州准独角兽企业榜单'
      ]
    },
    {
      title: 2020,
      eventList: [
        '同创伟业领投1.2亿C轮融资',
        '杭州市企业高新技术研究开发中心',
        '2020年度创新发展示范引领企业',
        'WIA2020全球人工智能创新TOP50',
        '2020年度智慧城市领域最具影响力创新企业TOP10',
        '成为省工商联执委企会',
        '发布校园系列场景'
      ]
    },
    {
      title: 2019,
      eventList: [
        '超亿元B轮融资',
        '11月发布智能终端D3，开启多模态交互新模式',
        '国家高新技术企业',
        '杭州市高新技术企业',
        '浙江大学校友创业大赛总决赛二等奖'
      ]
    },
    {
      title: 2018,
      eventList: [
        '入选胡润百富2018中国最具投资价值新星企业百强榜',
        '荣获2018第七届中国财经峰会最具投资价值奖',
        '6月发布智能终端D2开启新工作方式',
        '12月联合钉钉发布理想办公室',
        '中国创新创业大赛优胜奖'
      ]
    },
    {
      title: 2017,
      eventList: [
        '获阿里巴巴与浙大联创A轮战略融资',
        '联合钉钉发布智能前台M2荣获德国红点设计奖',
        '打造云考勤新模式，开启数字前台新时代'
      ]
    },
    {
      title: 2016,
      eventList: ['魔点诞生，专注空间智能']
    }
  ]
  mounted() {
    this.init()
  }

  private async init() {
    this.activeIndex = this.list[0].title
  }

  /**
   * @Description: 年份切换
   * @params {*}
   * @return {*}
   * @param {*} num
   */
  private handlerTab(num: number) {
    this.activeIndex = num
  }
}
