
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class WapTopNav extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  private page!: string

  @Watch('$route')
  WatchRouter() {
    const production = (this.$route.params || {}).production || (this.$route.query || {}).production
    if (production) {
      this.$emit('change-production', production)
      this.scrollY = 0
    }
    if (this.$route.path === '/') {
      (this.$refs.topNav as any).style.background = 'rgba(0, 0, 0, 0.2)'
      window.addEventListener('scroll', this.handleScroll)
    } else {
      (this.$refs.topNav as any).style.background = '#070721'
      window.removeEventListener('scroll', this.handleScroll)
    }
    this.status = 'close'
    // this.init()
  }
  @Watch('status')
  WatchStatus(val: string) {
    if (val === 'open') {
      this.scrollY = window.scrollY
    }
    document.body.style.position = val === 'close' ? 'static' : 'fixed'
    // 兼容顶部菜单定位后滚动不了问题
    ;(document.getElementById('html') as any).style.overflowY = val === 'close' ? 'auto' : 'hidden'
    document.body.style.overflowY = val === 'close' ? 'auto' : 'hidden'

    window.scrollTo(0, this.scrollY)
  }

  // 菜单打开／关闭状态
  private status = 'close'
  // 菜单数据源
  private navTabList: any = []
  // 二级菜单数据源
  private children = []
  private scrollY = 0
  private startY = 0
  private endY = 0
  private productList = [] // 所有产品集合

  private mounted() {
    this.init()
  }
  private beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  private async init() {
    document.body.style.position = 'static'
    if (this.$route.path === '/') {
      window.addEventListener('scroll', this.handleScroll)
    } else {
      (this.$refs.topNav as any).style.background = '#070721'
    }
    const res = await this.fetchDictDetail('website_menus')
    this.navTabList = JSON.parse(res.data.dictValue).data

    const _res = await this.fetchDictDetail('website_hard')
    this.productList = JSON.parse(_res.data.dictValue).data

    this.methodResetMenu(true)
  }

  /**
   * @Description: 菜单开关状态设置
   * @return {*}
   */
  private handleStatus() {
    if (this.$route.path === '/') {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) {
        (this.$refs.topNav as any).style.background = '#070721'
      } else {
        (this.$refs.topNav as any).style.background = 'rgba(0, 0, 0, 0.2)'
      }
    }

    if (this.status === 'open') {
      this.status = 'close'
    } else {
      this.status = 'open'
      ;(this.$refs.topNav as any).style.background = '#070721'
      // 开启时菜单选中项置为第一个
      for (let i = 0; i < this.navTabList.length; i++) {
        this.navTabList[i].show = false
      }
      this.navTabList[0].show = true
      this.children = this.navTabList[0].children
      // 获取新品列表
      const newTagList: any = []
      ;(this.productList as any).forEach(item => {
        if (item.isNew) {
          newTagList.push(item.productEn)
        }
      })
      // 把导航配置上对应新品标签
      this.children.forEach((menu: any) => {
        menu.isNew = false
        // 新路由带=号，旧路由不带，做兼容
        const _arr = menu.url.split('=')
        const name = _arr[1] ? _arr[1] : menu.url.split('/')[2]

        if (newTagList.includes(name)) {
          menu.isNew = true
        }
      })
    }
  }

  /**
   * @Description: 跳转首页
   * @return {*}
   * @param {*} item
   */
  private handlerToHome() {
    this.methodResetMenu(false)
    this.status = 'close'
    this.$router.push({
      path: '/'
    })
  }

  /**
   * @Description: 打开一级菜单
   * @return {*}
   * @param {*} item
   * @param {*} index
   */
  private handlerOpen(item: any, index: number) {
    if (item.children.length === 0) {
      this.handleJump(item)
      if (item.url.indexOf('http') === -1) item.visit = true
    } else {
      for (let i = 0; i < this.navTabList.length; i++) {
        this.navTabList[i].show = +i === index ? !item.show : false
      }
      this.children = item.children
    }
  }

  /**
   * @Description: 页面跳转
   * @return {*}
   * @param {*} item
   */
  private handleJump(item: any) {
    if (item.url.indexOf('http') !== -1) {
      window.open(item.url)
    } else {
      for (let i = 0; i < this.navTabList.length; i++) {
        this.navTabList[i].show = false
        this.navTabList[i].visit = this.navTabList[i].name === item.parentName ? true : false
      }
      this.$router.push({
        path: item.url
      })
      // 兼容顶部菜单定位后滚动不了问题
      document.body.style.position = 'static'
      ;(document.getElementById('html') as any).style.overflowY = 'auto'
      document.body.style.overflowY = 'auto'
    }
    this.status = 'close'
  }

  /**
   * @Description: 跳转外链
   * @params {*}
   * @return {*}
   * @param {*} url
   */
  handlerLink(url: string) {
    window.open(url)
  }

  /**
   * @Description: 跳转到魔蓝平台介绍页
   * @params {*}
   * @return {*}
   */
  handlerToMagiccube() {
    this.$router.push('/magicube')
    this.status = 'close'
    for (let i = 0; i < this.navTabList.length; i++) {
      this.navTabList[i].visit = false
    }
  }

  /**
   * @Description: 滚动
   * @params {*}
   * @return {*}
   */
  handleScroll(e: any) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    if (this.status === 'open') {
      (this.$refs.topNav as any).style.background = '#070721'
    } else if (scrollTop > 0) {
      (this.$refs.topNav as any).style.background = '#070721'
    } else {
      (this.$refs.topNav as any).style.background = 'rgba(0, 0, 0, 0.2)'
    }
    e.stopPropagation()
  }

  /**
   * @Description: 获取菜单数据
   * @return {*}
   */
  private fetchBarTree() {
    return this.$get('barTree', { type: 1 })
  }

  /**
   * @Description: 查询产品详情
   * @return {*}
   */
  private fetchDictDetail(dictCode) {
    return this.$post('dictDetail', { dictCode })
  }

  /**
   * @Description: 重置菜单
   * @params {*} isInit:是否是初始化菜单
   * @return {*}
   */
  private methodResetMenu(isInit: boolean) {
    this.navTabList.forEach((item: any) => {
      item.show = false
      item.visit = false
      // 智能硬件写入全部按钮
      if (item.name === '智能硬件' && isInit) {
        item.children.push({
          name: '查看更多',
          url: '/hardware',
          superId: item.id
        })
      }
    })
  }
}
