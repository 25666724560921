/*
 * @Description:
 * @version:
 * @Author: huhuan
 * @Date: 2020-09-07 11:16:29
 * @LastEditors: huhuan
 * @LastEditTime: 2022-03-04 15:06:30
 */
import { apiHost } from 'CONFIG'

const API: any = {
  // 分类获取导航栏树（PC可用）
  barTree: {
    host: apiHost,
    path: '/website/Navigation/Bar/tree',
    method: 'get'
  },
  // 获取上架魔点动态列表（适用PC）
  mdDynamicPage: {
    host: apiHost,
    path: '/website/md/dynamic/page',
    method: 'get'
  },
  // 获取banner列表
  bannerList: {
    host: apiHost,
    path: '/website/banner/list',
    method: 'get'
  },
  // 获取行业合作案例
  cooperationCaseList: {
    host: apiHost,
    path: '/website/project/case/list',
    method: 'get'
  },
  // 联系我们
  postContact: {
    host: apiHost,
    path: '/website/customer/counsel/save',
    method: 'post'
  },
  // 获取分类资源列表
  getResourcesList: {
    host: apiHost,
    path: '/website/resource/category/resources',
    method: 'get'
  },
  // 获取职位类目列表
  recruitCategoryList: {
    host: apiHost,
    path: '/website/recruit/category/list',
    method: 'get'
  },
  // 获取职位列表
  jobPage: {
    host: apiHost,
    path: '/website/job/page',
    method: 'get'
  },
  // 获取资源详情
  jobDetail: {
    host: apiHost,
    path: '/website/job/detail',
    method: 'get'
  },
  // 获取新闻列表
  getNewsList: {
    host: apiHost,
    path: '/website/md/dynamic/page',
    method: 'get'
  },
  // 获取新闻详情
  getNewsDetail: {
    host: apiHost,
    path: '/website/md/dynamic/detail',
    method: 'get'
  },
  // 获取字典详情
  dictDetail: {
    host: apiHost,
    path: '/dict/detail',
    method: 'post'
  },
  // 分页查询字典
  dictPage: {
    host: apiHost,
    path: '/dict/page',
    method: 'post'
  }
}
export default API
