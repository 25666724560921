
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class WapTopNav extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  private page!: string

  @Watch('$route')
  WatchRouter() {
    for (let i = 0; i < this.navTabList.length; i++) {
      this.navTabList[i].show = false
    }
  }

  private navTabList: any = []

  private mounted() {
    this.init()
  }

  private async init() {
    const res = await this.fetchbarTree()
    this.navTabList = res.data
  }

  /**
   * @Description: 打开一级菜单
   * @return {*}
   * @param {*} item
   * @param {*} index
   */
  private handlerOpen(item: any, index: number) {
    for (let i = 0; i < this.navTabList.length; i++) {
      this.navTabList[i].show = +i === index ? !item.show : false
    }
    this.$forceUpdate()
    if (item.navigationBarTreeNodes.length === 0) {
      this.handleJump(item)
    }
  }

  /**
   * @Description: 页面跳转
   * @return {*}
   * @param {*} item
   */
  private handleJump(item: any) {
    if (item.url.indexOf('http') !== -1) {
      window.open(item.url)
    } else {
      this.$router.push({
        path: item.url
      })
    }
  }

  /**
   * @Description: 获取菜单数据
   * @return {*}
   */
  private fetchbarTree() {
    return this.$get('barTree', { type: 2 })
  }
}
