
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageOpenApi extends Vue {
  mounted() {
    new Swiper('.swiper-container', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination'
      }
    })
    this.init()
  }

  private async init() {}
}
