
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageJoinDetail extends Vue {
  private info = {}
  mounted() {
    this.init()
  }
  private async init() {
    const res = await this.fetchJobDetail()
    this.info = res.data
  }

  /**
   * @Description: 返回
   * @params {*}
   * @return {*}
   */
  private handlerBack() {
    this.$router.go(-1)
  }

  /**
   * @Description: 获取详情
   * @params:
   * @return {*}
   */
  fetchJobDetail() {
    return this.$post('jobDetail', { id: this.$route.query.id })
  }
}
