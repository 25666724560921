
import { Component, Vue } from 'vue-property-decorator'
import WapConcatUs from '@/components/WapConcatUs.vue'

@Component({
  components: {
    WapConcatUs
  }
})
export default class PageAttendance extends Vue {
  private options = {
    licenseKey: 'PageAttendance',
    scrollOverflow: false
  }
  // 客户心声选中项
  private active = 0

  private isScroll = false

  private counselType = 7

  created() {
    if (window.screen.height <= 667) {
      this.isScroll = true
    }
  }
  mounted() {}
}
