
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageJoin extends Vue {
  // 是否显示选择下拉
  private showMask = false
  // 菜单
  private menu: any = []
  // 内容
  private contentList = []
  // 招聘总数
  private count = 0
  // 搜索选中项
  private activeIndex = 0
  private selectedName = '职位类型'
  mounted() {
    (document.getElementById('mask') as any).addEventListener('touchmove', function(event: any) {
      event.preventDefault()
    })
    this.init()
  }

  private async init() {
    const res1 = await this.fetchRecruitCategoryList()
    this.menu = res1.data
    this.count = 0
    this.menu.forEach((item: any) => {
      this.count += item.jobNumber
    })
    this.menu.unshift({ name: '全部', jobNumber: this.count })
    const res2 = await this.fetchJobPage(res1.data[0].id)
    this.contentList = res2.data.data
  }

  /**
   * @Description: 点击职位类型
   * @params {*}
   * @return {*}
   */
  private handlerSelect() {
    (this.$refs['maskContent'] as any).style.marginTop = (document.getElementById('topNav') as any).offsetHeight + 'px'
    window.scrollTo(0, (this.$refs['banner'] as any).offsetHeight)
    this.showMask = true
  }

  /**
   * @Description: 选择职位
   * @params {*}
   * @return {*}
   */
  private handlerItem(data: any) {
    this.$router.push({
      path: '/joinDetail',
      query: {
        id: data.id,
        count: `${this.count}`
      }
    })
  }

  /**
   * @Description: 点击遮罩层
   * @params {*}
   * @return {*}
   */
  private handlerMask() {
    this.showMask = false
  }

  /**
   * @Description: 选择类型
   * @params {*}
   * @return {*}
   */
  private async handlerType(data: any, index: number) {
    const res = await this.fetchJobPage(data.id)
    this.contentList = res.data.data
    this.activeIndex = index
    this.selectedName = data.name
  }

  /**
   * @Description: 获取资源类目列表
   * @params:
   * @return {*}
   */
  private fetchRecruitCategoryList() {
    return this.$get('recruitCategoryList')
  }

  /**
   * @Description: 获取列表数据
   * @params:
   * @return {*}
   */
  private fetchJobPage(id: number) {
    const postData = {
      recruitCategoryIds: id,
      pageNo: 1,
      pageSize: 10000
    }
    return this.$get('jobPage', postData)
  }
}
