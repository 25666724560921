
import { Component } from 'vue-property-decorator'
import showToast from '../../components/Toast/index'
import Vue from 'vue'
@Component
export default class PageApplication extends Vue {
  private postData = {
    contactPersonName: '',
    phone: '',
    contactName: '',
    email: '',
    counselType: 2
  }
  private isCanSave = true
  private isNameEmpty = false
  private isMobileEmpty = false
  private isErrorMobile = false
  private isCompneyEmpty = false
  private isErrorEmail = false

  private handleSave() {
    // 防止多次点击
    if (!this.isCanSave) {
      return
    }
    // 重置错误信息
    this.isNameEmpty = false
    this.isMobileEmpty = false
    this.isCompneyEmpty = false
    this.isErrorMobile = false
    this.isErrorEmail = false
    const _phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    const _emailReg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
    // 判断姓名非空
    if (this.postData.contactPersonName === '') {
      this.isNameEmpty = true
    }
    // 判断手机号非空
    if (this.postData.phone === '') {
      this.isMobileEmpty = true
    }
    // 判断公司名非空
    if (this.postData.contactName === '') {
      this.isCompneyEmpty = true
    }
    // 先判断必填项 必填项都填了继续
    if (this.postData.contactPersonName === '' || this.postData.phone === '' || this.postData.contactName === '') {
      return
    }
    // 校验手机号
    if (!_phoneReg.test(this.postData.phone)) {
      this.isErrorMobile = true
    }
    // 校验邮箱
    if (!_emailReg.test(this.postData.email) && !!this.postData.email) {
      this.isErrorEmail = true
    }
    // 校验都通过继续
    if (this.isErrorMobile || this.isErrorEmail) {
      return
    }
    this.isCanSave = false
    this.$post('postContact', this.postData)
      .then(() => {
        this.isCanSave = true
        this.postData = {
          contactPersonName: '',
          phone: '',
          contactName: '',
          email: '',
          counselType: 2
        }
        showToast({
          message: '提交成功',
          type: 'success'
        })
      })
      .catch(() => {
        this.isCanSave = true
        showToast({
          message: '服务器开小差了',
          type: 'error'
        })
      })
  }
}
