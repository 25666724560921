/* 缓存操作 */
export const productionData = [
  {
    type: 'Mbell',
    name: '魔点 Mbell',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/mBell/1.jpg')
      },
      {
        imgUrl: require('../assets/product/mBell/2.jpg')
      },
      {
        imgUrl: require('../assets/product/mBell/3.jpg')
      },
      {
        imgUrl: require('../assets/product/mBell/4.jpg')
      },
      {
        imgUrl: require('../assets/product/mBell/5.jpg')
      },
      {
        imgUrl: require('../assets/product/wap-product-bottom.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/mBell/线框图.jpg'),
        dataList: []
      },
      {
        styleName: '规格参数',
        imgUrl: '',
        dataList: [
          { title: '产品型号', content: 'MZ311A' },
          { title: '通讯', content: '无线双向收发模块，433.92MHz' },
          { title: '铃声', content: '可支持多款铃声' },
          { title: '指示灯', content: '支持黄色、蓝色两个指示灯' },
          { title: '发射功率', content: '20dBm' },
          { title: '应用距离', content: '室内≥15m，室外≥80m' },
          { title: '供电电源', content: '3V，两节5号电池' },
          { title: '工作温度', content: '-10℃~50℃' }
        ]
      }
    ]
  },
  {
    type: 'm3',
    name: '魔点M3',
    purchaseUrl: 'https://item.m.jd.com/product/10038438224065.html',
    productionList: [
      {
        imgUrl: require('../assets/product/m3/1.jpg')
      },
      {
        imgUrl: require('../assets/product/m3/2.png')
      },
      {
        imgUrl: require('../assets/product/m3/3.png')
      },
      {
        imgUrl: require('../assets/product/m3/4.png')
      },
      {
        imgUrl: require('../assets/product/m3/5.png')
      },
      {
        imgUrl: require('../assets/product/m3/6.png')
      },
      {
        imgUrl: require('../assets/product/m3/7.png')
      },
      {
        imgUrl: require('../assets/product/m3/8.png')
      },
      {
        imgUrl: require('../assets/product/m3/9.png')
      },
      {
        imgUrl: require('../assets/product/m3/10.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/m3/11.png'),
        dataList: []
      },
      {
        styleName: '规格参数',
        imgUrl: '',
        dataList: [{ title: '产品型号', content: 'MY5105A' }]
      },
      {
        styleName: '系统配置',
        imgUrl: '',
        dataList: [
          { title: 'CPU', content: '双核ARM处理器' },
          { title: '操作系统', content: 'Linux' },
          { title: '内存', content: '512MB' },
          { title: 'ROM', content: '4GB' }
        ]
      },
      {
        styleName: '硬件参数',
        imgUrl: '',
        dataList: [
          { title: '摄像头', content: '200万像素星光宽动态摄像头' },
          { title: '喇叭', content: '支持' },
          { title: '电源', content: '5V 2A' },
          { title: '机身接口', content: 'Type-C USB' },
          { title: '呼吸灯', content: '支持' },
          { title: 'WIFI', content: '支持 802.11b/g /n，支持2.4G' },
          { title: '蓝牙', content: '支持' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '人脸底库', content: '3000' },
          { title: '识别距离', content: '0.3~4m（室内无逆光环境）' },
          { title: '识别速度', content: '<0.2s' },
          { title: '多人识别', content: '支持' },
          { title: '打卡记录', content: '120000' },
          { title: '活体检测', content: '支持' },
          { title: '工作温度', content: '-10℃~40℃' }
        ]
      }
    ]
  },
  {
    type: 'mz310',
    name: '魔点MZ310',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/mz310/1.png')
      },
      {
        imgUrl: require('../assets/product/mz310/2.png')
      },
      {
        imgUrl: require('../assets/product/mz310/3.png')
      },
      {
        imgUrl: require('../assets/product/mz310/4.png')
      },
      {
        imgUrl: require('../assets/product/mz310/5.png')
      },
      {
        imgUrl: require('../assets/product/mz310/6.png')
      },
      {
        imgUrl: require('../assets/product/mz310/7.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: 'DDR', content: '4GB' },
          { title: '存储', content: '64GB' }
        ]
      },
      {
        styleName: '视频接入',
        imgUrl: '',
        dataList: [
          {
            title: '视频压缩标准',
            content: '分辨率 1080P 的 RTSP 流接入，支持H.264、H.265'
          }
        ]
      },
      {
        styleName: '硬件接口',
        imgUrl: '',
        dataList: [
          {
            title: '网络接口',
            content: '10/100/1000M 自适应以太网口2个'
          },
          { title: 'USB', content: 'USB 2.0，USB 3.0' },
          { title: 'HDMI', content: '支持' },
          { title: '按键', content: 'Reset' },
          { title: '串口', content: 'RS485' },
          { title: 'TF 卡接口', content: '内置' },
          { title: '报警输入/输出', content: '8路输入，8路输出' }
        ]
      },
      {
        styleName: '音频',
        imgUrl: '',
        dataList: [
          {
            title: '1路输入，1路输出',
            content: ''
          }
        ]
      },
      {
        styleName: '智能功能',
        imgUrl: '',
        dataList: [
          {
            title: '电源指示灯',
            content: '支持'
          },
          { title: '运行状态灯', content: '支持' },
          { title: '工作温度', content: '-20°C~65°C' },
          { title: '电源适配器', content: 'DC12V 2A' },
          { title: '安装方式', content: '桌面摆放、机架固定安装、壁装' },
          { title: '包装尺寸', content: '287*240*112mm' },
          { title: '外壳', content: '全铝合金无风扇封闭式设计 233.8mm*147.2mm*53mm' }
        ]
      }
    ]
  },
  {
    type: 'y3s',
    name: '魔点Y3s',
    purchaseUrl: 'https://item.m.jd.com/product/10045303969386.html',
    productionList: [
      {
        imgUrl: require('../assets/product/y3s/1.png')
      },
      {
        videoUrl: 'https://img.moredian.com/postImage/2022/5/30/10/website_resource_1653877129921.mp4',
        videoPoster: 'https://img.moredian.com/postImage/2022/5/30/10/website_resource_1653878580130.png'
      },
      {
        imgUrl: require('../assets/product/y3s/2.jpg')
      },
      {
        imgUrl: require('../assets/product/y3s/3.png')
      },
      {
        imgUrl: require('../assets/product/y3s/4.png')
      },
      {
        imgUrl: require('../assets/product/y3s/5.png')
      },
      {
        imgUrl: require('../assets/product/y3s/6.png')
      },
      {
        imgUrl: require('../assets/product/y3s/7.png')
      },
      {
        imgUrl: require('../assets/product/y3s/8.jpg')
      },
      {
        imgUrl: require('../assets/product/y3s/9.jpg')
      },
      {
        imgUrl: require('../assets/product/y3s/10.png')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/y3/9.png'),
        dataList: []
      },
      {
        styleName: '规格参数',
        imgUrl: '',
        dataList: [{ title: '产品型号', content: 'MY5105A' }]
      },
      {
        styleName: '系统配置',
        imgUrl: '',
        dataList: [
          { title: 'CPU', content: '双核ARM处理器' },
          { title: '操作系统', content: 'Linux' },
          { title: '内存', content: '512MB' },
          { title: 'ROM', content: '4GB' }
        ]
      },
      {
        styleName: '硬件参数',
        imgUrl: '',
        dataList: [
          { title: '显示屏', content: '5英寸IPS全视角LCD触摸屏，分辨率720*1280' },
          { title: '摄像头', content: '双摄200万，支持星光宽动态' },
          { title: '电源', content: '12V 1A' },
          { title: '刷卡', content: '支持（Type A/Type B 非接触式IC卡 13.65MHz）' },
          { title: '双向无线门铃', content: '支持' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '人脸底库', content: '3000' },
          { title: '识别距离', content: '0.3-1.5m' },
          { title: '识别速度', content: '<0.3s' },
          { title: '离线记录', content: '10000' },
          { title: '活体检测', content: '支持' }
        ]
      },
      {
        styleName: '工作温度',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-20~60℃' },
          { title: '工作湿度', content: '10~90%RH' }
        ]
      }
    ]
  },
  {
    type: 'my3',
    name: '魔点MY3',
    purchaseUrl: 'https://item.m.jd.com/product/10042537575712.html',
    productionList: [
      {
        imgUrl: require('../assets/product/my3/1.png')
      },
      {
        imgUrl: require('../assets/product/my3/2.png')
      },
      {
        imgUrl: require('../assets/product/my3/3.png')
      },
      {
        imgUrl: require('../assets/product/my3/4.png')
      },
      {
        imgUrl: require('../assets/product/my3/5.png')
      },
      {
        imgUrl: require('../assets/product/my3/6.png')
      },
      {
        imgUrl: require('../assets/product/my3/7.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {},
    ruleList: [
      {
        styleName: '规格参数',
        imgUrl: 'https://img.moredian.com/postImage/2023/2/17/11/website_resource_1676606086631.png'
      }
    ]
  },
  {
    type: 's5',
    name: '魔点S5',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/s5/1.png')
      },
      {
        imgUrl: require('../assets/product/s5/2.jpg')
      },
      {
        imgUrl: require('../assets/product/s5/3.png')
      },
      {
        imgUrl: require('../assets/product/s5/4.png')
      },
      {
        imgUrl: require('../assets/product/s5/5.png')
      },
      {
        imgUrl: require('../assets/product/s5/6.png')
      },
      {
        imgUrl: require('../assets/product/s5/7.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/s5/8.png'),
        dataList: [
          { title: '重量', content: '1.16KG' },
          { title: '颜色', content: '太空灰' },
          { title: '安装方式', content: '立柱' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: 'Hisi3516' },
          { title: '内存', content: '512MB' },
          { title: 'ROM', content: '8G' },
          { title: '显示屏', content: '5英寸IPS触控屏，分辨率720*1280' },
          { title: '摄像头', content: '200W双目摄像头，支持宽动态' },
          { title: '防护等级', content: 'IP65' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个一体化音腔扬声器', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统升级按键（Update）', content: '' },
          { title: '1个DC电源接头', content: '' },
          { title: '1个10根信号线束', content: '' },
          { title: '1个RJ45网口', content: '' },
          { title: '1个TYPE-C接口', content: '' },
          { title: '1个TYPE-A USB接口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Linux' },
          { title: '人脸容量', content: '20000张' },
          { title: '离线容量', content: '50000条' },
          { title: '识别距离', content: '0.3~2m' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个智能人脸闸机柱', content: '' },
          { title: '1个电源适配器', content: '' },
          { title: '1个电源线', content: '' },
          { title: '1个立柱', content: '' },
          { title: '1个螺母', content: '' },
          { title: '1个硅胶圈（上方机器侧）', content: '' },
          { title: '1个金属硅胶圈（下方大螺母侧）', content: '' },
          { title: '1个三包卡', content: '' },
          { title: '1个使用说明书', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-20℃~60℃' },
          { title: '整机功耗', content: '≤15W' }
        ]
      }
    ]
  },
  {
    type: 'mxb',
    name: '魔小白',
    purchaseUrl: 'https://item.m.jd.com/product/10034438013185.html',
    productionList: [
      {
        imgUrl: require('../assets/product/mxb/1.png')
      },
      {
        imgUrl: require('../assets/product/mxb/2.png')
      },
      {
        imgUrl: require('../assets/product/mxb/3.png')
      },
      {
        imgUrl: require('../assets/product/mxb/4.png')
      },
      {
        imgUrl: require('../assets/product/mxb/5.png')
      },
      {
        imgUrl: require('../assets/product/mxb/6.png')
      },
      {
        imgUrl: require('../assets/product/mxb/7.png')
      },
      {
        imgUrl: require('../assets/product/mxb/8.png')
      },
      {
        imgUrl: require('../assets/product/mxb/9.png')
      },
      {
        imgUrl: require('../assets/product/mxb/10.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/mxb/11.png'),
        dataList: [
          { title: '重量', content: '128.5g' },
          { title: '颜色', content: '白色' },
          { title: '安装方式', content: '挂墙' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '双核ARM' },
          { title: '内存', content: '512MB' },
          { title: 'ROM', content: '4G' },
          { title: '显示屏', content: '2.8英寸触摸屏，分辨率240*320' },
          { title: 'Wi-Fi', content: '802.11 b/g/n 2.4G' },
          { title: '蓝牙', content: 'BT4.2' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个显示屏', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置键（Reset）', content: '' },
          { title: '1个网口', content: '' },
          { title: '1个10PIN', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Linux' },
          { title: '人脸底库', content: '300张' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个挂墙支架', content: '' },
          { title: '1个10PIN接线端子', content: '' },
          { title: '4个螺钉', content: '' },
          { title: '2个膨胀管', content: '' },
          { title: '1个三包卡', content: '' },
          { title: '1个使用说明书', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~50℃' },
          { title: '整机功耗', content: '≤12W' }
        ]
      }
    ]
  },
  {
    type: 't1',
    name: '魔点T1',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/t1/1.png')
      },
      {
        imgUrl: require('../assets/product/t1/2.png')
      },
      {
        imgUrl: require('../assets/product/t1/3.png')
      },
      {
        imgUrl: require('../assets/product/t1/4.png')
      },
      {
        imgUrl: require('../assets/product/t1/5.png')
      },
      {
        imgUrl: require('../assets/product/t1/6.png')
      },
      {
        imgUrl: require('../assets/product/t1/7.png')
      },
      {
        imgUrl: require('../assets/product/t1/8.png')
      },
      {
        imgUrl: require('../assets/product/t1/9.jpg')
      },
      {
        imgUrl: require('../assets/product/t1/10.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {},
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/t1/11.png'),
        dataList: [
          { title: '尺寸', content: '513.7*381.5*20mm' },
          { title: '重量', content: '5.2kg' },
          { title: '颜色', content: '太空灰' },
          { title: '安装方式', content: '挂墙' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '产品型号', content: 'T1' },
          { title: '处理器', content: '8核64位处理器' },
          { title: '操作系统', content: 'Android' },
          { title: '内存', content: '2G' },
          { title: 'ROM', content: '32G(支持扩展128G)' },
          { title: '摄像头', content: '200万高清宽动态摄像头(可选1080P广角摄像头)' },
          { title: '显示屏', content: '21.5英寸LED高清IPS屏，分辨率1920*1080' },
          { title: '人脸底库', content: '10000' },
          { title: '识别距离', content: '1.5m' },
          { title: '识别准确率', content: '99.99%' },
          { title: '识别速度', content: '<0.3S' },
          { title: '活体检测', content: '支持' },
          { title: 'WIFI', content: '802.11b/g/n 2.4G&5G' },
          { title: '有线网络', content: '10M/100M' },
          { title: '蓝牙', content: 'Bluetooth4.0' },
          { title: '扬声器', content: '2* 2W/8Ω立体声喇叭' },
          { title: '麦克风', content: '抗噪咪头' },
          { title: 'RF', content: '支持IC/ID读卡，13.5MHz通用频率' },
          { title: '接口', content: 'USB2.0*2、RJ45、HDMI、3.5mm耳机座、TF卡座' },
          { title: '电源', content: '12V/3A' },
          { title: '工作功率', content: '24W' },
          { title: '工作温度', content: '0~55℃' }
        ]
      }
    ]
  },
  {
    type: 'd3',
    name: '魔点D3',
    purchaseUrl: 'https://item.m.jd.com/product/10021127199976.html',
    productionList: [
      {
        imgUrl: require('../assets/product/d3/wap-d3-1.png')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-2.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-3.png')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-4.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-5.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-6.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-7.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-8.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-9.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-10.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-11.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-13.png')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-14.jpg')
      },
      {
        imgUrl: require('../assets/product/d3/wap-d3-15.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      // position: 1,
      // videoUrl: 'http://package.moredian.com/website/moredian.mp4'
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/d3/wap-d3_size.png'),
        dataList: [
          { title: '重量', content: '659.7g' },
          { title: '颜色', content: '深灰色' },
          { title: '安装方式', content: '支持墙面、玻璃、门柱、大理石、立柱等多种安装方式' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '8核，64位ARM处理器' },
          { title: '内存', content: '2G' },
          { title: 'ROM', content: '16G' },
          { title: '显示屏', content: '8英寸触摸屏，IPS，显示分辨率1280*800' },
          { title: '摄像头', content: '三摄，200万高清像素' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '2个一体化音腔扬声器', content: '' },
          { title: '4个麦克风', content: '' },
          { title: '1个Type-C接口', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置按键（Reset）', content: '' }
        ]
      },
      {
        styleName: 'HUB接口',
        imgUrl: '',
        dataList: [
          { title: '1个Type-C头', content: '' },
          { title: '1个USB', content: '' },
          { title: '1个10Pin端子', content: '' },
          { title: '1个RJ45网口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Android 9.0' },
          { title: '人脸容量', content: '30000张' },
          { title: '离线记录', content: '30000条' },
          { title: '识别距离', content: '1.5米内' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个电源适配器', content: '' },
          { title: '1个电源线', content: '' },
          { title: '1个挂墙件', content: '' },
          { title: '1个HUB', content: '' },
          { title: '1个信号线束', content: '' },
          { title: '2个GB/T819m4*25mm螺钉', content: '' },
          { title: '2个GB/T846m4*25mm螺钉', content: '' },
          { title: '4个M2_5×4.0_外观定制螺钉', content: '' },
          { title: '2个膨胀管∅6×30', content: '' },
          { title: '1个3M胶', content: '' },
          { title: '1个三包卡', content: '' },
          { title: '1个说明书', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~50°C' },
          { title: '整机功耗', content: '<36W' }
        ]
      }
    ]
  },
  {
    type: 'd2',
    name: '魔点D2',
    purchaseUrl: 'https://item.m.jd.com/product/10021123134630.html',
    productionList: [
      {
        imgUrl: require('../assets/product/d2/wap-d2-img1.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img2.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img3.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img4.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img5.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img6.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img7.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img8.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img-cewen.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img9.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img10.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img11.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img12.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img13.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 1,
      videoUrl: 'http://package.moredian.com/website/moredian.mp4'
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/d2/wap-d2-size.png'),
        dataList: [
          { title: '重量', content: '355.5g' },
          { title: '颜色', content: '太空灰' },
          { title: '安装方式', content: '挂墙' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '四核，1.45GHz' },
          { title: '内存', content: '1G' },
          { title: 'ROM', content: '8G' },
          { title: '显示屏', content: '5英寸触摸屏，IPS，显示分辨率1280*720' },
          { title: '摄像头', content: '200万高清像素，1/2.7英寸CMOS' },
          { title: 'WiFi', content: 'IEEE 802.11b/g/n，2.4GHz' },
          { title: '蓝牙', content: 'Bluetooth4.0+LE' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个补光灯圈', content: '' },
          { title: '2个红外补光灯圈', content: '' },
          { title: '1个一体化音腔扬声器', content: '' },
          { title: '1个10PIN接口', content: '' },
          { title: '1个网口', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置按键（Reset）', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Android 6.0' },
          { title: '人脸容量', content: '10000张' },
          { title: '离线记录', content: '10000条' },
          { title: '识别距离', content: '1.5米内' },
          { title: '活体识别', content: '支持' },
          { title: '手机录人脸', content: '支持手机端录入人脸' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '10PIN接线端子 ×1', content: '' },
          { title: 'GB/T819 m4*25mm螺钉 × 2', content: '' },
          { title: 'GB/T846 m4*25mm螺钉 × 4', content: '' },
          { title: '膨胀管 ∅6x30 × 4', content: '' },
          { title: '电源适配器 ×1', content: '' },
          { title: '挂墙支架 ×1', content: '' },
          { title: '三包卡×1', content: '' },
          { title: '使用说明书×1', content: '' },
          { title: '电源线×1', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~40°C' },
          { title: '整机功耗', content: '<15W' }
        ]
      }
    ]
  },
  {
    type: 'g2',
    name: '魔点G2',
    purchaseUrl: 'https://item.m.jd.com/product/10021111459290.html',
    productionList: [
      {
        imgUrl: require('../assets/product/g2/wap-g2-img1.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img2.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img3.png')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img4.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img5.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img6.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img7.png')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img8.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img9.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img10.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img11.jpg')
      },
      {
        imgUrl: require('../assets/product/g2/wap-g2-img12.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: [
        {
          imgUrl: require('../assets/product/g2/wap-g2-img10-1.png')
        },
        {
          imgUrl: require('../assets/product/g2/wap-g2-img10-2.png')
        },
        {
          imgUrl: require('../assets/product/g2/wap-g2-img10-3.png')
        }
      ]
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/g2/wap-g2-size.png'),
        dataList: [
          { title: '重量', content: '169.8g' },
          { title: '颜色', content: '灰色' },
          { title: '安装方式', content: '支持墙面、玻璃、门柱等多种安装方式' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '四核，1.45GHz' },
          { title: '内存', content: '1GB' },
          { title: 'ROM', content: '8GB' },
          { title: '显示屏', content: '4英寸触摸屏，显示分辨率480*800' },
          { title: '摄像头', content: '200万高清像素，1/2.7英寸CMOS' },
          { title: 'WiFi', content: 'IEEE 802.11b/g/n，2.4GHz' },
          { title: '蓝牙', content: 'Bluetooth4.0+LE' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个白光补光灯圈', content: '' },
          { title: '1个红外补光灯圈', content: '' },
          { title: '1个一体化音腔扬声器', content: '' },
          { title: '1个G2连接线接口', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置按键（Reset）', content: '' }
        ]
      },
      {
        styleName: 'HUB接口',
        imgUrl: '',
        dataList: [
          { title: '1个G2连接线接口', content: '' },
          { title: '1个MIR-USB', content: '' },
          { title: '1个8pin端子', content: '' },
          { title: '1个RJ45网口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Android 6.0' },
          { title: '人脸容量', content: '3000张' },
          { title: '离线记录', content: '10000条' },
          { title: '识别距离', content: '1米内' },
          { title: '活体识别', content: '支持' },
          { title: '手机录人脸', content: '支持手机端录入人脸' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '电源适配器 × 1', content: '' },
          { title: '电源线 × 1', content: '' },
          { title: '挂墙件 × 1', content: '' },
          { title: 'G2连接线 × 1', content: '' },
          { title: 'Hub × 1', content: '' },
          { title: '8Pin接线端子 × 1', content: '' },
          { title: '螺钉 × 5', content: '' },
          { title: '膨胀管∅60 × 30 × 2', content: '' },
          { title: '三包卡 × 1', content: '' },
          { title: '使用说明书 × 1', content: '' },
          { title: '3M胶 × 1', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~40°C' },
          { title: '整机功耗', content: '<24W' }
        ]
      }
    ]
  },
  {
    type: 'y2',
    name: '魔点Y2',
    purchaseUrl: 'https://item.m.jd.com/product/10021132927201.html',
    productionList: [
      {
        imgUrl: require('../assets/product/y2/1.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/2.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/3.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/4.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/5.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/6.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/7.png')
      },
      {
        imgUrl: require('../assets/product/y2/8.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/9.jpg')
      },
      {
        imgUrl: require('../assets/product/y2/10.png')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {},
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/y2/y2线框手机端.png'),
        dataList: [
          { title: '重量', content: '128.5g' },
          { title: '颜色', content: '黑色' },
          { title: '安装方式', content: '挂墙' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '双核ARM处理器' },
          { title: '显示屏', content: '2.8英寸触摸屏' },
          { title: 'WiFi', content: '802.11 b/g/n 2.4G' },
          { title: '蓝牙', content: 'BT4.2' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个显示屏', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置键（Reset）', content: '' },
          { title: '1个网口', content: '' },
          { title: '1个线束接口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Linux' },
          { title: '人脸容量', content: '1500张' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个挂墙支架', content: '' },
          { title: '1个10PIN线束', content: '' },
          { title: '4个螺钉', content: '' },
          { title: '2个膨胀管', content: '' },
          { title: '1个使用说明书', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~50℃' },
          { title: '整机功耗', content: '≤12W' }
        ]
      }
    ]
  },
  {
    type: 'mg3',
    name: '魔点MG3',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img1.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img2.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img3.jpg')
      },
      {
        imgUrl: 'https://img.moredian.com/postImage/2023/11/15/15/website_resource_1700035189610.jpg'
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img5.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img6.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img7.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img8.jpg')
      },
      {
        imgUrl: require('../assets/product/mg3/wap-mg3-img9.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {
      position: 9,
      specialType: '',
      top: '26',
      height: '40',
      specialList: [
        // {
        //   imgUrl: require('../assets/product/mg3/wap-mg3-img10-1.png')
        // },
        // {
        //   imgUrl: require('../assets/product/mg3/wap-mg3-img10-2.png')
        // },
        // {
        //   imgUrl: require('../assets/product/mg3/wap-mg3-img10-3.png')
        // }
      ]
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/mg3/wap-mg3-img10.png'),
        dataList: [
          { title: '重量', content: '700g' },
          { title: '颜色', content: '太空灰' },
          { title: '安装方式', content: '墙面、立柱' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '8核，64位ARM处理器' },
          { title: '内存', content: '2G' },
          { title: 'ROM', content: '16G' },
          { title: '显示屏', content: '8英寸触摸屏，IPS，显示分辨率800*1280' },
          { title: '摄像头', content: '双摄，200W 宽动态' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个speaker', content: '' },
          { title: '2个麦克风', content: '' },
          { title: '1个Type-C接口', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置按键（Reset）', content: '' }
        ]
      },
      {
        styleName: 'HUB接口',
        imgUrl: '',
        dataList: [
          { title: '1个Type-C接口', content: '' },
          { title: '1个Micro USB接口', content: '' },
          { title: '1个RJ45网口', content: '' },
          { title: '8pin端子接口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Andriod 9.0' },
          { title: '人脸容量', content: '30000张' },
          { title: '离线容量', content: '30000张' },
          { title: '识别距离', content: '1.5米内' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个挂墙件', content: '' },
          { title: '1个电源适配器', content: '' },
          { title: '1个电源线', content: '' },
          { title: '1个Type-C线', content: '' },
          { title: '1个线束', content: '' },
          { title: '1个8Pin接线端子', content: '' },
          { title: '1个HUB', content: '' },
          { title: '2个ST4x25螺钉', content: '' },
          { title: '2个M4x26螺钉', content: '' },
          { title: '4个M2.5x4螺钉', content: '' },
          { title: '2个φ6x30膨胀管', content: '' },
          { title: '1个三包卡', content: '' },
          { title: '1个使用说明', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-20℃~60℃' },
          { title: '整机功耗', content: '≤19W' }
        ]
      }
    ]
  },
  {
    type: 'mog1',
    name: '魔橙MOG1',
    productionList: [
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_1.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_2.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_3.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_4.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_5.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_6.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_7.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_8.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_9.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_10.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_11.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_12.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_13.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_14.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_15.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_16.png')
      },
      {
        imgUrl: require('../assets/product/mog1/wap_mog1_17.png')
      }
    ],
    specialData: {
      specialType: '',
      specialList: []
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/mog1/wap_mog1_size.png'),
        dataList: [
          { title: '重量', content: '700g' },
          { title: '颜色', content: '阳极氧化太空灰' },
          { title: '安装方式', content: '挂墙、嵌墙、立柱' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '8核，64位ARM处理器' },
          { title: '内存', content: '2GB' },
          { title: 'ROM', content: '16GB' },
          { title: '显示屏', content: '8英寸IPS触摸屏，分辨率800*1280' },
          { title: '摄像头', content: '红外摄像头,200万高清像素1/2.7英寸CMOS' }
          // { title: 'WiFi', content: 'IEEE 802.11b/g/n，2.4GHz' },
          // { title: '蓝牙', content: 'Bluetooth4.0+LE' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个一体化音腔扬声器', content: '' },
          { title: '1个麦克风', content: '' },
          { title: '1个MOG1 HUB连接线接口', content: '' },
          { title: '1个系统重启设置按键（Restart）', content: '' },
          { title: '1个系统恢复出厂设置按键（Reset）', content: '' }
        ]
      },
      {
        styleName: 'HUB接口',
        imgUrl: '',
        dataList: [
          { title: '1个MOG1 HUB连接线接口', content: '' },
          { title: '1个Micro-USB', content: '' },
          { title: '1个8Pin端子', content: '' },
          { title: '1个RJ45网口', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Andriod 8.1' },
          { title: '人脸容量', content: '30000张' },
          { title: '离线容量', content: '30000条' },
          { title: '识别距离', content: '1.5米内' },
          { title: '活体识别', content: '支持' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '1个电源适配器', content: '' },
          { title: '1个电源线', content: '' },
          { title: '1个挂墙件', content: '' },
          { title: '1个Type-C线', content: '' },
          { title: '1个HUB', content: '' },
          { title: '1个8Pin接线端子 M2.5', content: '' },
          { title: '1个线束', content: '' },
          { title: '4个M2.5螺钉', content: '' },
          { title: '2个ST4螺钉', content: '' },
          { title: '2个M4螺钉', content: '' },
          { title: '2个膨胀管∅6x30', content: '' },
          { title: '1个三包卡', content: '' },
          { title: '1个说明书', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~55°C' },
          { title: '整机功耗', content: '<15W' }
        ]
      }
    ]
  },
  {
    type: 'mh2',
    name: '魔点MH2',
    purchaseUrl: '',
    productionList: [
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img1.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img2.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img3.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img4.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img5.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img6.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img7.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img8.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img9.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img10.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img11.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img12.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img13.jpg')
      },
      {
        imgUrl: require('../assets/product/mh2/wap-mh2-img14.jpg')
      }
    ],
    specialData: {
      position: 6,
      specialType: 'clickSwipe',
      top: '42',
      height: '60',
      specialList: [
        {
          imgUrl: require('../assets/product/mh2/wap-mh2-img7-1.jpg')
        },
        {
          imgUrl: require('../assets/product/mh2/wap-mh2-img7-2.jpg')
        },
        {
          imgUrl: require('../assets/product/mh2/wap-mh2-img7-3.jpg')
        }
      ]
    },
    ruleList: [
      {
        styleName: '外观尺寸',
        imgUrl: require('../assets/product/mh2/wap-mh2-size.png'),
        dataList: [
          { title: '重量', content: '未知' },
          { title: '颜色', content: '白色' },
          { title: '安装方式', content: '桌面式' }
        ]
      },
      {
        styleName: '硬件配置',
        imgUrl: '',
        dataList: [
          { title: '处理器', content: '四核，64位RAM处理器' },
          { title: '内存', content: '1G' },
          { title: 'ROM', content: '8G' },
          { title: '显示屏', content: '10.1英寸IPS触摸屏，分辨率1280*800' },
          { title: '摄像头', content: '红外摄像头、彩色摄像头，2M，1/2.7英寸CMOS' },
          { title: 'WiFi', content: 'IEEE 802.11b/g/n，2.4GHz' },
          { title: '蓝牙', content: 'Bluetooth4.0+LE' },
          { title: '读卡器', content: '内置身份证读卡器' }
        ]
      },
      {
        styleName: '整机接口',
        imgUrl: '',
        dataList: [
          { title: '1个补光灯带', content: '' },
          { title: '2个红外补光灯圈', content: '' },
          { title: '1个一体化音腔扬声器', content: '' },
          { title: '1个网口', content: '' },
          { title: '1个USB接口', content: '' },
          { title: '1个电源接口', content: '' },
          { title: '1个内置身份读卡器', content: '' }
        ]
      },
      {
        styleName: '软件参数',
        imgUrl: '',
        dataList: [
          { title: '操作系统', content: 'Android 6.0' },
          { title: '活体识别', content: '支持' },
          { title: '识别距离', content: '0.5米' }
        ]
      },
      {
        styleName: '配件',
        imgUrl: '',
        dataList: [
          { title: '三包卡 × 1', content: '' },
          { title: '使用说明书 × 1', content: '' },
          { title: '电源线 × 1', content: '' }
        ]
      },
      {
        styleName: '工作环境',
        imgUrl: '',
        dataList: [
          { title: '工作温度', content: '-10~40°C' },
          { title: '整机功耗', content: '<15W' }
        ]
      }
    ]
  },
  // {
  //   type: 'mt21',
  //   name: '魔点MT21',
  //   purchaseUrl: 'https://detail.tmall.com/item.htm?spm=a211lz.success.0.0.16192b90RZRrLS&id=624813242078',
  //   productionList: [
  //     {
  //       imgUrl: require('../assets/product/mt21/1.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/2.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/3.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/4.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/5.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/6.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/7.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/8.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mt21/9.jpg')
  //     }
  //   ],
  //   specialData: {
  //     // position: 1,
  //     // videoUrl: 'http://package.moredian.com/website/moredian.mp4'
  //   },
  //   ruleList: [
  //     {
  //       styleName: '外观尺寸',
  //       imgUrl: require('../assets/product/mt21/移动端.png'),
  //       dataList: [
  //         { title: '重量', content: '52g' },
  //         { title: '颜色', content: '深灰色' },
  //         { title: '安装方式', content: '支持魔点D2/G2智能门卫组合安装' },
  //         { title: '产品结构组成', content: '外壳 线路板 感温头' },
  //         { title: '测量部位', content: '手腕' },
  //         { title: '测量范围', content: '32.0°C~42.9°C' },
  //         { title: '最大允许误差', content: '±0.2°C(35.0°C≤t≤42.0°C) ±0.3°C(32.0°C≤t<35.0°C, 42.0°C<t≤42.9°C)' },
  //         { title: '分度值', content: '0.1°C' },
  //         { title: '临床准确度', content: '本产品经临床验证，其临床最大允许误差为±0.3°C' },
  //         { title: '防电击保护分类', content: '内部电源' },
  //         { title: '功耗', content: '≤30mW' },
  //         { title: '提示功能', content: '测试位置提示，发烧发热提示' },
  //         {
  //           title: '生理效应',
  //           content:
  //             '本产品在使用过程中，对人体无毒、无刺激、无致敏等不良反应。清洁、消毒方法  请按照制造商推荐方法进行清洁'
  //         },
  //         { title: '进液防护', content: '普通设备' },
  //         { title: '运行模式', content: '连续运行' },
  //         {
  //           title: '安全分类',
  //           content: '不能在与空气混合的易燃麻醉气或与氧或氧化亚氮混合的易燃麻醉气气体情况下使用的设备'
  //         },
  //         { title: '电磁兼容性', content: '1组、B类设备' },
  //         { title: '禁忌症', content: '无' },
  //         { title: '适用范围', content: '通过测量手腕的热辐射来显示被测对象的体温' },
  //         { title: '使用期限', content: '60个月' }
  //       ]
  //     },
  //     {
  //       styleName: '工作环境',
  //       imgUrl: '',
  //       dataList: [
  //         { title: '本产品使用环境', content: '10℃~40℃, 湿度不大于85%RH' },
  //         { title: '储存和运输环境', content: '-20℃~55℃, 湿度不大于93%RH  大气压力 70kPa~106kPa' }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   type: 'mz401',
  //   name: '魔点MZ401',
  //   productionList: [
  //     {
  //       imgUrl: require('../assets/product/mz401/1.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/2.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/3.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/4.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/5.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/6.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/7.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/mz401/8.jpg')
  //     },
  //     {
  //       imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
  //     }
  //   ],
  //   specialData: {},
  //   ruleList: [
  //     {
  //       styleName: '规格参数',
  //       imgUrl: 'https://img.moredian.com/postImage/2021/6/1/10/website_resource_1622514144964.png',
  //       dataList: [
  //         { title: '型号', content: 'MZ401A（单机芯)、 MZ401B（双机芯）' },
  //         { title: '尺寸', content: '1200 * 200* 1000mm' },
  //         { title: '材料', content: '304不锈钢，上盖厚1.5mm, 机身1.2mm' },
  //         { title: '电机', content: '直流有刷电机' },
  //         { title: '电机寿命', content: '300万次' },
  //         { title: '红外对数', content: '4对' },
  //         { title: '输入电压', content: 'AC 220V 50Hz' },
  //         { title: '输出电压', content: 'DC 24V 6.5A' },
  //         { title: '功率', content: '150W' },
  //         { title: '过道宽度', content: '55-80cm' },
  //         { title: '开闸时间', content: '1秒' },
  //         { title: '通行速度', content: '常开60人/分钟，常闭35人/分钟' },
  //         { title: '摆臂', content: '不锈钢摆臂' },
  //         { title: '工作环境温度', content: '-25°C~70°C' },
  //         { title: '工作环境湿度', content: '5%-90%湿度不凝露' },
  //         { title: '防护等级', content: 'IPX4' },
  //         { title: '输入控制信号', content: '继电器开关信号' },
  //         { title: '防夹', content: '支持' },
  //         { title: '防尾随', content: '支持' }
  //       ]
  //     }
  //   ]
  // },
  {
    type: 'mz402',
    name: '魔点MZ402',
    productionList: [
      {
        imgUrl: require('../assets/product/mz402/1.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/2.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/3.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/4.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/5.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/6.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/7.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/8.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/9.jpg')
      },
      {
        imgUrl: require('../assets/product/mz402/10.jpg')
      },
      {
        imgUrl: require('../assets/product/d2/wap-d2-img14.jpg')
      }
    ],
    specialData: {},
    ruleList: [
      {
        styleName: '规格参数',
        imgUrl: 'https://img.moredian.com/postImage/2021/6/1/10/website_resource_1622514178761.png',
        dataList: [
          { title: '型号', content: 'MZ402A (单机芯)、MZ402B (双机芯)' },
          { title: '尺寸', content: '1200 * 200* 1000mm' },
          { title: '材料', content: '304不锈钢，上盖厚1.5mm, 机身1.2mm' },
          { title: '电机', content: '直流有刷电机' },
          { title: '电机寿命', content: '600万次' },
          { title: '红外对数', content: '4对' },
          { title: '输入电压', content: 'AC 220V 50Hz' },
          { title: '输出电压', content: 'DC 24V 6.5A' },
          { title: '功率', content: '150W' },
          { title: '过道宽度', content: '60-105cm' },
          { title: '开闸时间', content: '0.7秒' },
          { title: '通行速度', content: '常开60人/分钟，常闭35人/分钟' },
          { title: '摆臂', content: '不锈钢摆臂,单双向可选' },
          { title: '工作环境温度', content: '-25°C~70°C' },
          { title: '工作环境湿度', content: '5%-90%湿度不凝露' },
          { title: '防护等级', content: 'IPX4' },
          { title: '输入控制信号', content: '继电器开关信号' },
          { title: '防夹', content: '支持' },
          { title: '防撞', content: '支持' },
          { title: '防尾随', content: '支持' }
        ]
      }
    ]
  }
]
