
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { timeFormat } from '../../utils/comUtils'

@Component
export default class PageNewsDetail extends Vue {
  // 详情数据
  private detail = {
    nextArticle: {},
    previousArticle: {}
  }
  // 详情内容
  private content = []

  @Watch('$route')
  WatchRouter() {
    window.scrollTo(0, 0)
    this.init()
  }

  mounted() {
    this.init()
  }

  private async init() {
    const res = await this.fetchGetNewsDetail()
    this.detail = res.data
    this.content = JSON.parse(res.data.sections)
  }

  /**
   * @Description: 下一篇按钮
   * @params {*}
   * @return {*}
   */
  private handlerNext(data: any) {
    this.$router.push({
      path: '/news/detail',
      query: {
        id: data.id
      }
    })
  }

  /**
   * @Description: 返回
   * @params {*}
   * @return {*}
   */
  private handlerBack() {
    this.$router.push({ path: '/news/list' })
  }

  /**
   * @Description: 获取新闻详情
   * @params {*}
   * @return {*}
   */
  private fetchGetNewsDetail() {
    return this.$get('getNewsDetail', { id: this.$route.query.id })
  }

  /**
   * @Description: 时间格式化
   * @params {*}
   * @return {*}
   * @param {*} date
   */
  private methodTimeFormat(date: number) {
    return date ? timeFormat(new Date(date)) : ''
  }
}
