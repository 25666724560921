export interface UserInfo {
  userName: string;
  supperAdmin: boolean;
}

export interface State {
  userInfo: UserInfo;
}

// 初始状态
const state: State = {
  userInfo: {
    userName: '',
    supperAdmin: false
  }
}

export default state
