
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component
export default class PageApplication extends Vue {
  private cooperationCaseList = []

  mounted() {
    new Swiper('.swiper-container', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination'
      }
    })
    this.init()
  }

  private async init() {
    const res = await this.fetchGetCooperationCaseList()
    this.cooperationCaseList = res.data
  }

  private fetchGetCooperationCaseList() {
    return this.$get('cooperationCaseList', { caseType: 1 })
  }

  private handleConcat() {
    this.$router.push('/industry/contact')
  }
}
