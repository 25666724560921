<template>
  <div id="protocol-privacy">
    <div class="m-h-96"></div>
    <!-- <my-markdown class="protocol-privacy"></my-markdown> -->
    <div class="protocol-privacy">
      <h1>魔点《隐私政策》</h1>
      <p class="m-mt-60">版本更新日期：2021年01月28日</p>
      <p>版本生效日期：2021年01月28日</p>
      <p class="m-mt-60 m-bold">欢迎您使用魔点的产品和服务！我们非常重视您的隐私保护和个人信息保护。</p>
      <p
        >【特别提示】请仔细阅读《魔点隐私政策》，并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可联系我们的客服咨询，如您不同意本政策中的任何条款，您应立即停止访问或使用魔点的产品及服务。</p
      >
      <p class="m-mt-60">
        杭州魔点科技有限公司（下文简称“魔点”、“我们”、“我们的”）深知隐私对您的重要性，并会尊重您的隐私。您在使用我们的产品或服务时，我们可能会收集、保存和使用您的相关信息。我们希望通过《魔点隐私政策》（下文简称“本政策”）向您说明在您使用我们的产品或服务时，我们如何收集、使用、保存和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。但本政策可能并不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息可能由魔点在补充政策中，或者在收集数据时提供的通知中发布。
      </p>
      <p>
        本政策适用于魔点产品及服务，包括魔点科技网站相关及魔点自有品牌智能硬件（例如魔点D2智能人脸考勤门禁机及其配件。如我们关联公司的产品或服务中使用了魔点提供的产品或服务但未设独立的隐私政策的，则本政策同样适用于该部分产品或服务。<b
          >如某款魔点产品有单独的隐私政策，则该产品的隐私政策将优先适用。该产品隐私政策未涵盖的内容，以本隐私政策内容为准。</b
        >
      </p>
      <p>
        本政策不适用于其他第三方向您提供的服务，第三方提供的服务适用于第三方提供的隐私政策。第三方包括通过钉钉、支付宝、微信等为您提供产品和服务的第三方、您通过钉钉、支付宝、微信等所访问的网站运营方以及通过钉钉、支付宝、微信等第三方SDK/API接收您的信息并为您提供服务的第三方。
      </p>
      <p class="m-bold">
        若您使用魔点的产品或服务，即表示您认同我们在本政策中所述内容。在将您的信息用于本隐私权政策未涵盖的用途时，我们会事先征求您的同意。
      </p>
      <h2 class="m-mt-30">第一部分 定义</h2>
      <p class="m-mt-20">
        <b>魔点：</b>
        <span>
          是指杭州魔点科技有限公司及其有关联关系的公司，包括但不限于杭州魔橙科技有限公司等公司。魔点各项服务的经营及责任主体由实际提供该服务的公司承担。“关联关系”是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权利或事实上构成实际控制的其他关系。
        </span>
      </p>
      <p>
        <b>个人信息：</b>
        <span>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息。</span>
      </p>
      <p>
        <b>个人敏感信息</b>
        <span>
          指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息。
        </span>
      </p>
      <p>
        <b>个人信息删除：</b>
        <span>指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span>
      </p>
      <h2 class="m-mt-30">第二部分 隐私权政策</h2>
      <p class="m-bold m-mt-20">本政策将帮助您了解以下内容：</p>
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>二、我们如何使用 Cookie 和同类技术</p>
      <p>三、我们如何转让、公开披露您的个人信息</p>
      <p>四、我们如何保护和保存您的个人信息</p>
      <p>五、您的权利</p>
      <p>六、我们如何处理儿童的个人信息</p>
      <p>七、本政策更新及通知</p>
      <p>八、如何联系我们</p>
      <p>九、争议解决</p>
      <h3 class="m-mt-30">一、我们如何收集和使用您的个人信息</h3>
      <p class="m-mt-20">在您使用我们的产品和/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</p>
      <p class="m-bold">
        1.为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及服务；
      </p>
      <p class="m-bold">
        2.为实现向您提供我们产品及服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及服务的基本功能。
      </p>
      <p class="m-mt-20">您理解并同意：</p>
      <p class="m-bold">
        1.我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品和服务范围存在差异，相应的基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
      </p>
      <p>
        <b>
          2.为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用、保存。
        </b>
        <span>
          在此过程中，如果您有任何疑问、意见或建议的，您可通过各种联系方式与我们联系，我们会尽快为您作出解答。
        </span>
      </p>
      <p class="m-mt-20">我们会出于本政策所述的以下目的，收集和使用您的个人信息：</p>
      <h3 class="m-mt-20">(一)帮助您成为魔点的注册用户</h3>
      <p class="m-mt-20">
        为了接受魔点的全面服务，您应首先注册一个用户账号，您需要提供手机号码、姓名并创建用户名和密码。如果您仅需浏览魔点的解决方案、客户案例等相关介绍，您不需要注册成为用户并提供上述信息。
      </p>
      <p>
        <b>
          在注册的过程中，如果您提供性别、生日等基本信息，将有助于我们给您提供如生日特权等更个性化的服务。
        </b>
        <span>
          如果您不提供这些信息，将会影响到您使用个性化的服务，但不会影响使用魔点产品或服务的基本功能。
        </span>
      </p>
      <h3 class="m-mt-20">(二)向您提供产品和服务</h3>
      <p class="m-bold m-mt-20">1.您向我们提供的信息</p>
      <p>
        在服务使用过程中，您可以对魔点产品及服务的体验问题反馈，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们产品或服务。
      </p>
      <p class="m-bold">
        2.我们在您使用服务过程中收集的信息
      </p>
      <p>
        在您使用魔点产品和/或服务过程中，为向您提供更契合您需求的智能硬件和/或软件、页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们可能会收集关于您使用产品和/或服务（包括使用方式）过程中的信息，此类信息包括：
      </p>
      <p class="bold">(1)使用信息</p>
      <p>
        <b>
          如您需要通过魔点的智能人脸产品(见魔点智能硬件列表)以实现刷脸开门、刷脸考勤等功能，则需要您提供您的人脸照片。
        </b>
        <span>如果您不提供人脸照片，将无法使用魔点智能人脸产品，但不会影响使用魔点基本功能或服务及浏览网页信息。</span>
      </p>
      <p class="m-bold">
        (2)设备信息
      </p>
      <p>
        为了提供更好的产品和/或服务并改善用户体验，我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符、网络设备硬件地址MAC等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。
      </p>
      <p class="m-bold">
        (3)日志信息
      </p>
      <p>
        当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存，例如您对我们的产品和/或服务的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与钉钉等通讯软件通讯的信息以及访问服务的日期、时间、时长等。
      </p>
      <p class="m-bold">(4)唯一应用程序编号</p>
      <p>
        某些产品和服务包含唯一应用程序编号，当您安装、激活、更新、卸载相关产品和服务或当这些产品和服务定期与魔点通信（例如软件的更新）时，系统会将此编号以及与安装相关的信息（例如操作系统类型和应用程序版本号）发送给魔点。
      </p>
      <p class="m-bold">(5)第三方向我们提供的信息</p>
      <p>
        魔点将对其他用户进行与您相关的操作时收集关于您的信息，亦可能从魔点关联公司、合作第三方或通过其他合法途径获取的您的个人数据信息。
      </p>
      <p class="m-bold">(6)客服及争议处理</p>
      <p
        >当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。</p
      >
      <p>
        为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、工单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和工单信息。
      </p>
      <h3 class="m-bold m-mt-20">(三).为您展示和推送定制内容</h3>
      <p class="m-mt-20">
        为了将您感兴趣的服务信息展示给您，在符合相关法律法规的前提下，我们可能将通过平台所收集的信息用于我们的其他服务。例如，将您在使用我们平台某项服务时的信息，用于另一项服务中向您展示个性化的内容或广告、用于用户研究分析与统计等服务。
      </p>
      <h3 class="m-bold m-mt-20">(四). 提供或改进我们的产品和/或服务所需要的附加功能</h3>
      <p class="m-bold m-mt-20">
        为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用浏览及搜索等基本服务，但您可能无法获得这些附加服务。这些附加服务包括：
      </p>
      <p>
        <b>1. 基于摄像头（相机）的附加功能：</b>
        <span>您可在开启相机/摄像头权限后在 手机浏览器、钉钉、微信等功能中拍摄照片上传图片资料。</span>
      </p>
      <p>
        <b>2. 基于图片上传的附加功能：</b>
        <span>您可在开启相册权限后使用该功能上传您的图片/视频，以实现在手机浏览器、钉钉、微信等功能中上传照片。</span>
      </p>
      <p>
        <b>3.基于蓝牙的附加功能：</b>
        <span>您可在开启蓝牙权限后使用该功能，以实现设备开门、设备激活等功能。</span>
      </p>
      <h3 class="m-mt-20">(五).其他用途</h3>
      <p class="m-mt-20"></p>
      <p>
        1.当我们要将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，或者我们主动从第三方处获取您的个人信息，会事先征求您的同意。
      </p>
      <p class="m-bold">
        2.请注意，您提供的信息中可能会涉及您或他人的个人信息甚至个人敏感信息。若您提供的信息中含有他人的个人信息，在向魔点提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第八条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。
      </p>
      <p
        >请您注意，上述附加功能可能需要您在您的设备中向我们开启您的相机(摄像头)、相册(图片库)、蓝牙的访问权限，以实现这些功能所涉及的信息的收集和使用。我们在您首次使用该类附加功能需开启相应权限时，弹窗对您进行提示，你选择开启相应权限，则可进一步使用相应服务。</p
      >
      <p class="m-bold">
        请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
      </p>
      <h3 class="m-mt-20">(六). 征得授权同意的例外</h3>
      <p class="m-mt-20">根据相关法律法规规定，以下情形中收集您的个人信息无需征得您的授权同意：</p>
      <p class="m-bold">1、与国家安全、国防安全有关的；</p>
      <p class="m-bold">2、与公共安全、公共卫生、重大公共利益有关的；</p>
      <p class="m-bold">3、与刑事侦查、起诉、审判和判决执行等有关的；</p>
      <p class="m-bold">4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</p>
      <p class="m-bold">5、所收集的个人信息是您自行向社会公众公开的；</p>
      <p class="m-bold">6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p class="m-bold">7、根据您的要求签订合同所必需的；</p>
      <p class="m-bold">8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
      <p class="m-bold">9、法律法规规定的其他情形。</p>
      <p>
        如我们停止运营魔点产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
      </p>
      <h2 class="m-mt-30">二、我们如何使用 Cookie 和同类技术</h2>
      <p class="m-mt-20">
        为实现您联机体验的个性化需求，使您获得更轻松的访问体验，我们会在您的计算机或移动设备上存储名为 Cookie
        的小数据文件。Cookie
        通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。
      </p>
      <p>
        <span>我们不会将 Cookie用于本政策所述目的之外的任何用途。</span>
        <b>您可根据自己的偏好管理或删除Cookies。</b>
        <span>
          您可以清除计算机上保存的所有Cookie，大部分网络浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie；另外，您也可以清除软件内保存的所有Cookie。但如果您这么做，您可能需要在每一次访问魔点网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
        </span>
      </p>
      <p>
        除 Cookie 外，我们还会在网站上使用包含一些电子图像，称为“单像素 GIF 文件”或“网络
        beacon”，它可以帮助网站计算浏览网页的用户或访问某些cookie。我们会通过网络beacon收集您浏览网页活动信息，例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
      </p>
      <h2 class="m-mt-30">三、我们如何披露您的个人信息</h2>
      <h3 class="m-mt-20 m-bold">(一).提供与转让</h3>
      <p class="m-mt-20">我们不会将您的个人信息提供、转让给任何公司、组织和个人，但以下情况除外</p>
      <p>（1）事先获得您明确的同意或授权；</p>
      <p>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
      <p>（3）根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
      <p>
        （4）当我们发生收购、兼并、重组、破产等情形时或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私权政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
      </p>
      <h3 class="m-mt-20">(二).公开披露</h3>
      <p class="m-mt-20">我们仅会在以下情况下披露您的个人信息：</p>
      <p>（1）根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
      <p>（2）基于法律法规、法律程序、诉讼或政府主管部门强制性要求下；</p>
      <p>
        <span>（3）</span>
        <b>
          如果我们确定您出现违反法律法规或严重违反魔点相关协议规则的情况，或为保护魔点及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或魔点相关协议规则征得您同意的情况下披露关于您的个人信息，
        </b>
        <span>包括相关违规行为以及魔点已对您采取的措施。</span>
      </p>
      <h3 class="m-mt-20">(三) 提供、转让、公开披露个人信息时事先征得授权同意的例外</h3>
      <p class="m-mt-20">在以下情形中，转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
      <p>（1）与国家安全、国防安全直接相关的；</p>
      <p>（2）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（3）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>（5）您自行向社会公众公开的个人信息；</p>
      <p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>（7）与我们履行法律法规规定的义务相关的法律法规规定的其他情形。</p>
      <p class="m-bold">
        根据法律规定，转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      </p>
      <h2 class="m-mt-30">四、我们如何保护和保存您的个人信息</h2>
      <h3 class="m-bold m-mt-20">(一)个人信息的保存</h3>
      <p class="m-mt-20">
        <span>1.保存期限：请您注意，在您在使用我们的产品与/或服务时所提供的所有个人信息，</span>
        <b>除非您删除或通过系统设置拒绝我们收集，我们将在您使用我们的产品和/或服务期间持续授权我们持续使用。</b>
        <span>在您申请注销账号并时，我们将停止使用并删除上述信息或做匿名化处理。</span>
      </p>
      <p>
        2.保存地域：上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会在符合国家对于信息出境的相关法律规定情况下，再单独征得您的授权同意。
      </p>
      <h3 class="m-mt-20">(二)个人信息的保护</h3>
      <p class="m-bold m-mt-20">1.安全措施</p>
      <p>
        魔点重视个人数据安全。我们会采用符合业界标准的安全防护措施，包括但不仅限于建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。为确保您个人信息的安全，我们有严格的信息安全规定和流程，并有专门的信息安全团队在公司内部严格执行上述措施。
      </p>
      <p>
        我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
      </p>
      <p class="m-bold m-mt-20">2.安全提醒</p>
      <p>
        (1)互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
      </p>
      <p>
        (2)互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏的，我们还将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
      </p>
      <p>
        (3)您在使用魔点的产品和/或服务时请勿将自认为隐私的信息发表、上传，也不可将该等信息通过魔点的服务器传播给他人，若因您该等行为引起隐私泄露，由您自行承担责任。
      </p>
      <p>
        (4)您请勿在使用魔点的产品和/或服务时公开透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的损失由您自行承担责任。
      </p>
      <p>
        (5)魔点一旦发现假冒、仿冒、盗用他人名义使用服务的，魔点有权立即删除用户信息并有权禁止其使用平台服务。
      </p>
      <p class="m-bold m-mt-20">3.安全事件通知</p>
      <p>
        (1)我们会制定相应的网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施。
      </p>
      <p>
        (2)在不幸发生个人信息安全事件后，我们将按照法律法规的要求及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      </p>
      <p>
        (3)如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过魔点提供的以及本政策第八章节的【如何联系我们】中的联系方式联络我们，以便我们采取相应措施。
      </p>
      <h2 class="m-mt-30">五、您的权利</h2>
      <p class="m-mt-20">
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      </p>
      <h3 class="m-mt-20">（一）访问和更正您的个人信息</h3>
      <p class="m-mt-20">
        1.除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：通过 网页应用 中【意见反馈】向我们进行反馈。
      </p>
      <p>
        2.如果您需要查阅您在使用魔点过程中产生的其他个人信息，在合理要求下并经验证核实您的身份后，我们会向您提供。若您无法通过上述方式访问或者更正您的个人信息，也可以通过本政策的【如何联系我们】提交或通过
        网页应用 中【意见反馈】向我们进行反馈。我们会在 15 个工作日内回复您的查阅请求。
      </p>
      <h3 class="m-mt-20">（二）删除您的个人信息</h3>
      <p class="m-mt-20">
        1.如果您需要删除您在使用魔点过程中产生的其他个人信息，在合理要求并经验证核实您的身份后，我们会向您提供。您也可以通过本政策的【如何联系我们】提交或通过
        网页应用 中【意见反馈】向我们进行反馈。我们会 在15 个工作日回复您的删除请求。
      </p>
      <p>2.在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p>(1)如果我们处理个人信息的行为违反法律法规；</p>
      <p>(2)如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p>(3)如果我们处理个人信息的行为违反了与您的约定；</p>
      <p>(4)如果您注销了个人在线帐号；</p>
      <p>(5)如果我们终止服务及运营。</p>
      <p>
        3.我们响应您的删除请求后还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
      </p>
      <p>
        4.当您从我们的服务中删除信息后，我们会及时从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <h3 class="m-mt-20">（三）改变您授权同意的范围</h3>
      <p class="m-mt-20"
        >每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一部分”）。除此之外，您可以与魔点客服联系或改变您的智能移动设备的设置等方式给予或收回您的授权同意。</p
      >
      <p>
        <span>
          当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。但请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的特定功能和/或服务。
        </span>
        <b>但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</b>
      </p>
      <h3 class="m-mt-20">（四）设备权限调用</h3>
      <p class="m-mt-20">
        我们在提供服务的过程中，可能需要您开通一些设备权限，例如通知、相册、相机、蓝牙等访问权限。您可以在您的设备的【设置】功能中随时选择关闭部分或者全部权限，从而拒绝我们收集您相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或政策。
      </p>
      <h3 class="m-mt-20">（五）注销您的账号</h3>
      <p class="m-mt-20">
        您可以在我们的平台中直接申请注销账号，具体可通过平台中【意见反馈】或【官网】中提供的联系方式，向我们申请注销和删除您的信息。您注销账号后，我们将停止为您提供产品和/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息或做匿名化处理。
      </p>
      <h3 class="m-mt-20">（六）响应您的上述请求</h3>
      <p class="m-mt-20">
        为保障安全，我们可能会先要求您验证自己的身份，然后再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。对于您的请求，我们原则上将于
        15 日内做出答复。
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </p>
      <h3 class="m-mt-20">（七）在以下情形中，按照法律法规要求，我们将无法响应您的请求：</h3>
      <p class="m-mt-20">1.与国家安全、国防安全直接相关的；</p>
      <p>2.与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>3.与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>4.有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>6.涉及商业秘密的；</p>
      <p>7.与我们履行法律法规规定的义务相关的；</p>
      <p>8.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您授权同意的。</p>
      <h2 class="m-mt-30">六、我们如何处理儿童的个人信息</h2>
      <p class="m-bold m-mt-20">
        我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。
      </p>
      <p class="m-bold">
        对于魔点可能会涉及儿童的产品和/或服务，需经父母（监护人）同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下收集、使用或公开披露此信息。
      </p>
      <p class="m-bold">
        尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。
      </p>
      <p class="m-bold">
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
      </p>
      <p class="m-bold">
        若您是儿童的监护人，当您对您所监护的儿童使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本政策的规定保护儿童用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
      </p>
      <h2 class="m-mt-30">七、本政策如何更新</h2>
      <p class="m-mt-20">
        魔点保留不时更新或修改本政策的权利。如该等变更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过显著的通知方式进行告知（包括我们会通过魔点公示的方式进行意见征集、公告通知甚至向您提供弹窗提示等）。我们会在本页面上发布对本政策所做的任何变更。
      </p>
      <p class="m-bold">
        若您不同意该等变更可以停止使用魔点产品和服务，若您继续使用我们的产品和/或服务，即表示您同意受修订后的本政策的约束。
      </p>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>
        （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p>
        （2）我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <p>（3）个人信息转让或公开披露的主要对象发生变化；</p>
      <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>（5）个人信息安全影响评估报告表明存在高风险时；</p>
      <p>（6） 其他可能对您的个人信息权益产生重大影响的变化时。</p>
      <h2 class="m-mt-30">八、如何联系我们</h2>
      <p class="m-mt-20">
        如果您对我们的隐私保护政策或数据处理有任何疑问、意见或建议，
        可以通过【7*12小时客服热线（400-033-7675）】与我们联系，我们邮寄联系方式如下：
      </p>
      <p>杭州魔点科技有限公司</p>
      <p>联系部门：魔点服务部</p>
      <p>地址：杭州市余杭区高教路959号未来Park园区3号楼7A</p>
      <p>邮编：310000</p>
      <p>电子邮箱：marketing@moredian.com</p>
      <p>一般情况下，我们将在收到您相关联系信息后15个工作日内回复。</p>
      <h2 class="m-mt-30">九、争议解决</h2>
      <p class="m-mt-20">
        <span>
          如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，
        </span>
        <b>
          您可以通过向本隐私权政策服务提供机构魔点所在地（杭州市余杭区）有管辖权的人民法院提起诉讼来寻求解决方案。
        </b>
      </p>
    </div>
  </div>
</template>

<script>
// import myMarkdown from '../../protocol/service.md'
export default {
  // components: { myMarkdown },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
#protocol-privacy {
  padding: 28.8px 28.8px 162px;
  color: #070721;
  p {
    font-size: 32px;
    line-height: 60px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 32px;
  }
  .m-mt-60 {
    margin-top: 60px;
  }
  .m-mt-30 {
    margin-top: 30px;
  }
  .m-mt-20 {
    margin-top: 20px;
  }
  .underline {
    text-decoration: underline;
  }
}
</style>
