
import { Component, Vue } from 'vue-property-decorator'
import WapConcatUs from '@/components/WapConcatUs.vue'

@Component({
  components: {
    WapConcatUs
  }
})
export default class PagePark extends Vue {
  private options = {
    licenseKey: 'PagePark',
    scrollOverflow: false
  }
  // 客户心声选中项
  private active = 0

  private isScroll = false

  private counselType = 6

  private images = ['https://img.moredian.com/postImage/2023/5/30/17/website_resource_1685438812115.png']
  created() {
    if (window.screen.height <= 667) {
      this.isScroll = true
    }
  }
  mounted() {}
}
