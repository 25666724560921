
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { saveAs } from 'file-saver'

@Component
export default class PageApplication extends Vue {
  private searchName = ''
  private helpList = []
  private status = 'init'
  // 搜索框是否focus
  private inputFocus = false

  mounted() {
    this.init()
  }

  private async init() {
    this.status = 'init'
    const _res = await this.fetchGetHelpList()
    this.helpList = _res.data || []
    console.log(this.helpList)
  }

  private async handleSearch() {
    this.helpList = []
    if (!this.searchName) {
      this.status = 'init'
      return this.init()
    }
    const _res = (await this.fetchGetHelpList()) || []
    for (let i = 0; i < _res.data.length; i++) {
      this.helpList = this.helpList.concat(_res.data[i].resources)
    }
    if (this.helpList.length < 1) {
      this.status = 'empty'
    } else {
      this.status = 'search'
    }
  }

  /**
   * @Description: 获取焦点
   * @params {*}
   * @return {*}
   */
  private handlerFocus() {
    this.inputFocus = true
  }
  /**
   * @Description: 失去焦点
   * @params {*}
   * @return {*}
   */
  private handlerBlur() {
    this.inputFocus = false
  }

  /**
   * @Description: 下载
   * @params {*}
   * @return {*}
   */
  private handlerDownLoad(file: any) {
    saveAs(file.resourceUrl, file.name)
  }

  private fetchGetHelpList() {
    return this.$get('getResourcesList', {
      name: this.searchName,
      resourceCategoryType: 1
    })
  }

  private handleClear() {
    this.searchName = ''
    this.init()
  }
}
