
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PageAbout extends Vue {
  mounted() {
    new Swiper('.swiper-container1', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination1'
      }
    })
    this.init()
  }

  private async init() {}
}
