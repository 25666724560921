
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class PageConcat extends Vue {
  // 当前定位
  private activeIndex = 1
  mounted() {
    this.init()
  }

  private async init() {}

  /**
   * @Description: 切换
   * @params {*}
   * @return {*}
   * @param {*} num
   */
  private handlerTab(num: number) {
    this.activeIndex = num
  }
}
