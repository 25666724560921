import Vue from 'vue';
// toast组件
import toastComponent from './CommonToast.vue'

// 返回一个 扩展实例构造器
const ToastConstructor = Vue.extend(toastComponent)

// 定义弹出组件的函数 接收2个参数, 要显示的文本 和 显示时间
function showToast(data: { message: any; type: string}) {
  const duration = 3000
  // 实例化一个 toast.vue
  const toastDom: any = new ToastConstructor({
    el: document.createElement('div'),
    data() {
      return {
        text: data.message,
        type: data.type,
        showWrap: true,
        showContent: true
      }
    }
  });
  // 把 实例化的 toast.vue 添加到 body 里
  document.body.appendChild(toastDom.$el);
  // 提前 250ms 执行淡出动画(因为我们再css里面设置的隐藏动画持续是250ms)
  setTimeout(() => { toastDom.showContent = false }, duration - 250)
  // 过了 duration 时间后隐藏整个组件
  setTimeout(() => { toastDom.showWrap = false }, duration)
}
export default showToast;