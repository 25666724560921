/*
 * @Description:
 * @version:
 * @Author: huhuan
 * @Date: 2021-01-29 15:04:14
 * @LastEditors: huhuan
 * @LastEditTime: 2022-03-04 14:39:23
 */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PageIndex from '@/views/PageIndex.vue'
import PageProduct from '@/views/PageProduct.vue' // 产品集合
import PageProductNew from '@/views/PageProductNew.vue' // 产品集合
import PageHardware from '@/views/PageHardware.vue' // 硬件集合
import PageOffice from '@/views/plan/PageOffice.vue' // 智能办公
import PageSchool from '@/views/plan/PageSchool.vue' // 智慧校园
import PageMedical from '@/views/plan/PageMedical.vue' // 智慧防疫
import PageAttendance from '@/views/plan/PageAttendance.vue' // 数字云考勤
import PagePark from '@/views/plan/PagePark.vue' // 智慧园区
import PageApplication from '@/views/industry/PageApplication.vue' // 行业应用
import PageContact from '@/views/industry/PageContact.vue' // 联系我们
import PageHelpCenter from '@/views/help/PageHelpCenter.vue' // 帮助中心
import PageIsv from '../views/ecology/PageIsv.vue' // ISV
import PageParticipate from '../views/ecology/PageParticipate.vue' // 加盟魔点
import PageSynopsis from '../views/synopsis/PageSynopsis.vue' // 关于魔点
import PageAbout from '../views/synopsis/PageAbout.vue' // 魔点简介
import PageAchievement from '../views/synopsis/PageAchievement.vue' // 大事记
import PageConcat from '../views/synopsis/PageConcat.vue' // 联系我们
import PageJoin from '../views/synopsis/PageJoin.vue' // 加入魔点
import PageJoinDetail from '../views/synopsis/PageJoinDetail.vue' // 加入魔点详情
import PageNewsList from '../views/news/PageNewsList.vue' // 魔点资讯
import PageNewsDetail from '../views/news/PageNewsDetail.vue' // 魔点资讯详情
import PageServiceProtocol from '../views/protocol/PageServiceProtocol.vue' // 服务协议
import PagePrivacy from '../views/protocol/PagePrivacy.vue' // 隐私政策
import PageOpenApi from '../views/openapi/PageOpenApi.vue' // 开放平台
import PageMagicube from '../views/magicube/PageMagicube.vue' // 魔蓝介绍页面

interface Meta {
  keepAlive: boolean;
}

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: PageIndex
  },
  {
    path: '/product',
    name: 'PageProductNew',
    component: PageProductNew
  },
  {
    path: '/product/:production?',
    name: 'PageProduct',
    component: PageProduct
  },
  {
    path: '/hardware',
    name: 'PageHardware',
    component: PageHardware
  },
  {
    path: '/plan/office',
    name: 'PageOffice',
    component: PageOffice
  },
  {
    path: '/plan/school',
    name: 'PageSchool',
    component: PageSchool
  },
  {
    path: '/plan/park',
    name: 'PagePark',
    component: PagePark
  },
  {
    path: '/plan/attendance',
    name: 'PageAttendance',
    component: PageAttendance
  },
  {
    path: '/plan/medical',
    name: 'PageMedical',
    component: PageMedical
  },
  {
    path: '/industry/application',
    name: 'PageApplication',
    component: PageApplication
  },
  {
    path: '/industry/contact',
    name: 'PageContact',
    component: PageContact
  },
  {
    path: '/help/center',
    name: 'PageHelpCenter',
    component: PageHelpCenter
  },
  {
    path: '/isv',
    name: 'PageIsv',
    component: PageIsv
  },
  {
    path: '/participate',
    name: 'PageParticipate',
    component: PageParticipate
  },
  {
    path: '/synopsis',
    name: 'PageSynopsis',
    component: PageSynopsis,
    redirect: '/synopsis/about',
    children: [
      {
        path: 'about',
        name: 'PageAbout',
        component: PageAbout,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'achievement',
        name: 'PageAchievement',
        component: PageAchievement,
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'concat',
        name: 'PageConcat',
        component: PageConcat,
        meta: {
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/join',
    name: 'PageJoin',
    component: PageJoin
  },
  {
    path: '/joinDetail',
    name: 'PageJoinDetail',
    component: PageJoinDetail
  },
  {
    path: '/news/list',
    name: 'PageNewsList',
    component: PageNewsList,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/news/detail',
    name: 'PageNewsDetail',
    component: PageNewsDetail
  },
  {
    path: '/protocol/service',
    name: 'PageServiceProtocol',
    component: PageServiceProtocol
  },
  {
    path: '/protocol/privacy',
    name: 'PagePrivacy',
    component: PagePrivacy
  },
  {
    path: '/openApi',
    name: 'PageOpenApi',
    component: PageOpenApi
  },
  {
    path: '/magicube',
    name: '魔蓝平台',
    component: PageMagicube
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && (to.meta as Meta).keepAlive) {
      return savedPosition
    }
    if (to.matched[0].path === from.matched[0].path) {
      return
    }
    return { x: 0, y: 0 }
  }
})

export default router
