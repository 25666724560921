
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageIndex extends Vue {
  // banner列表
  private bannerList = []
  // 解决方案
  private planList = [
    {
      img: require('../assets/index/智能办公.png'),
      title: '智慧办公',
      desc:
        '助力企业打造无人前台、人脸考勤、访客管理、智能会议、数据大屏等数字化办公新场景，定义企业自己的智能化工作方式。',
      url: '/plan/office'
    },
    {
      img: require('../assets/index/智慧校园.png'),
      title: '智慧校园',
      desc: '提供多维度校园场景解决方案，助力教育组织实现数字化升级，构建安全、智能、协同、家校共育的教育环境。',
      url: '/plan/school'
    },
    {
      img: require('../assets/index/attendance.png'),
      title: '数字化云考勤',
      desc: '面向连锁企业提供支持接入钉钉、微信小程序、其他OA系统的数字化云考勤方案。助力企业精准高效管理门店，提升算薪效率。',
      url: '/plan/attendance'
    }
  ]
  // 新闻动态
  private newsList = []
  // 友情logo
  private friendsList = [
    {
      img: require('../assets/index/logo_阿里巴巴.png')
    },
    {
      img: require('../assets/index/logo_阿里云.png')
    },
    {
      img: require('../assets/index/logo_蚂蚁金服.png')
    },
    {
      img: require('../assets/index/logo_钉钉.png')
    },
    {
      img: require('../assets/index/logo_华为.png')
    },
    {
      img: require('../assets/index/logo_高格空间.png')
    },
    {
      img: require('../assets/index/logo_中国联通.png')
    },
    {
      img: require('../assets/index/logo_中国电建.png')
    },
    {
      img: require('../assets/index/logo_中国铁建.png')
    },
    {
      img: require('../assets/index/logo_中国燃气.png')
    },
    {
      img: require('../assets/index/logo_中国石化.png')
    },
    {
      img: require('../assets/index/logo_浙江大学.png')
    }
  ]

  mounted() {
    this.init()
  }

  private async init() {
    const res1 = await this.fetchGetBannerList()
    this.bannerList = res1.data
    const res2 = await this.fetchMdDynamicPage()
    this.newsList = res2.data.data
    new Swiper('.swiper-container', {
      loop: true, // 循环模式选项
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },

      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination'
      }
    })
  }

  /**
   * @Description: 跳转到解决方案详情页
   * @params {*}
   * @return {*}
   */
  private handlerToDetail(item: any) {
    this.$router.push({ path: item.url })
  }

  /**
   * @Description: 跳转到新闻详情页
   * @params {*}
   * @return {*}
   */
  private handlerToNews() {
    this.$router.push({ path: '/news/list' })
  }

  /**
   * @Description: 获取banner列表
   * @params {*}
   * @return {*}
   */
  fetchGetBannerList() {
    return this.$get('bannerList', { type: 1 })
  }

  /**
   * @Description: 获取上架魔点动态列表（适用PC）
   * @return {*}
   */
  private fetchMdDynamicPage() {
    return this.$get('mdDynamicPage', { isRecommend: 1, pageNo: 1, pageSize: 3 })
  }

  /**
   * @Description: banner跳转
   * @return {*}
   */
  private handleBannerJumpPage(item: any) {
    if (item.openWay === 1) {
      location.href = item.linkH5Url
    } else {
      window.open(item.linkH5Url)
    }
  }

  // 新闻跳转
  private handleClickNews(news: any) {
    if (news.dynamicType === 1) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: news.id
        }
      })
    } else if (news.dynamicType === 2) {
      window.open(news.detailContentUrl)
    }
  }
}
