
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import WapTopNav from '@/components/WapTopNav.vue'

@Component({
  components: {
    WapTopNav
  }
})
export default class PageProduct extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  private page!: string

  @Watch('$route.params')
  WatchRouter() {
    this.currentProduct = {}
    this.init()
    this.handleClickTab(0)
  }

  private dictValue: any = {} // 字典值
  private currentProduct: any = {}
  private dictCode = this.$route.query.dictCode
  private tabIndex = 0
  private textAlertShow = false // 无购买地址时显示400电话弹窗
  private purchaseUrl = '' // 购买地址

  private mounted() {
    this.init()
  }

  private init() {
    this.dictCode = this.$route.query.dictCode
    this.fetchDictDetail().then(res => {
      this.dictValue = JSON.parse(res.data.dictValue)
    })
  }

  /**
   * @Description: 立即购买跳转
   * @return {*}
   */
  private handelJumpPage(path: string, req: any) {
    // 跳转路由
    if (path) {
      if (path.indexOf('http') !== -1) {
        window.open(path)
      } else {
        this.$router.push({
          path: path,
          query: req || ''
        })
      }
    }
  }
  /**
   * @Description: 立即购买
   * @return {*}
   */
  private handleClickBuy() {
    this.textAlertShow = true
  }
  /**
   * @Description: 切换规格详情和规格参数
   * @return {*}
   */
  private handleClickTab(index: number) {
    if (this.tabIndex === index) {
      return false
    }
    this.tabIndex = index
    window.scrollTo(0, 0)
  }

  /**
   * @Description: 关闭400电话弹窗
   * @return {*}
   */
  private handleClose() {
    this.textAlertShow = false
  }

  /**
   * @Description: 查询产品详情
   * @return {*}
   */
  private fetchDictDetail() {
    return this.$post('dictDetail', { dictCode: this.dictCode })
  }
}
