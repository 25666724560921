
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageMedical extends Vue {
  private options = {
    licenseKey: 'PageMedical',
    scrollOverflow: false
  }
  // 客户心声选中项
  private active = 0
  private isScroll = false
  created() {
    if (window.screen.height <= 667) {
      this.isScroll = true
    }
  }
  mounted() {
    new Swiper('.swiper-container1', {
      centeredSlides: true,
      slidesOffsetBefore: 30,
      slidesOffsetAfter: 30,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true
      },
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination1'
      }
    })
    const swiper = new Swiper('.swiper-container2', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination2'
      },
      on: {
        slideChange: () => {
          this.active = swiper.activeIndex
        }
      }
    })
    new Swiper('.swiper-container3', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination3'
      }
    })
  }
}
