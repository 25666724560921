/*
 * @Description:
 * @version:
 * @Author: huhuan
 * @Date: 2020-09-04 16:40:57
 * @LastEditors: huhuan
 * @LastEditTime: 2021-02-26 10:11:37
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MUI from 'md-ui-pc'
import axios from '@/axios'
import 'lib-flexible'
import VueFullPage from 'vue-fullpage.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAMap from 'vue-amap'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(VueFullPage)
Vue.use(ElementUI)
Vue.use(VueAMap)
Vue.use(Viewer, {
  defaultOptions: {
    navbar: false, // 显示缩略图导航
    toolbar: false, // 显示工具栏
    movable: false // 是否可以移动
  }
})

VueAMap.initAMapApiLoader({
  key: '4453712f704dd529b115464705bc0e89',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor'
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})

Vue.config.productionTip = false
Vue.use(MUI)

Vue.use(axios, {
  errorCallback: async (err: any, { isShowError = true, errMsg = '操作失败', isCover = false } = {}): Promise<void> => {
    Vue.prototype.$mloading(false)
    if (isShowError) {
      const _errMsg = isCover || !err.message ? errMsg : err.message
      await Vue.prototype.$mmessage.error(_errMsg)
    }
    if (err.result === 'error_http_login_expired') {
      // 登录失效时 重新跳转到登录页
      store.commit('removeToken')
      window.location.hash = '#/workspace'
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
