<template>
  <div id="protocol-service">
    <div class="m-h-96"></div>
    <!-- <my-markdown class="protocol-service"></my-markdown> -->
    <h1>魔点《服务协议》</h1>
    <p class="m-mt-60">版本更新日期：2021年01月28日</p>
    <p>版本生效日期：2021年01月28日</p>
    <p class="m-mt-60 m-bold">欢迎您使用魔点的产品和服务！我们非常重视您的隐私保护和个人信息保护。</p>
    <p
      >【特别提示】请仔细阅读《魔点服务协议》，并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可联系我们的客服咨询，如您不同意本政策中的任何条款，您应立即停止访问或使用魔点的产品及服务。</p
    >
    <!-- 第一部分 -->
    <!-- <p class="m-mt-60">第一部分 用户协议</p>
    <p class="m-bold">欢迎您使用我们的产品和服务！</p> -->
    <!-- 一 -->
    <h2 class="m-mt-30">一、服务条款的确认及接受</h2>
    <p class="m-mt-20">
      <span class="m-bold">
        1、在使用魔点产品及服务之前，您应当认真阅读并遵守《魔点服务协议》（以下简称“本协议”）以及《魔点隐私政策》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用、管辖条款。
      </span>
      <span>如您对本协议内容或页面提示信息有疑惑，请勿进行下一步操作，可向魔点客服进行咨询。</span>
    </p>
    <p class="m-bold">
      2、当您按照注册页面提示填写信息、勾选”阅读并同意本协议“选项且完成全部注册程序后，或您按照激活页面、弹窗提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他魔点允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得魔点对您问询的解答等理由，主张本协议无效或要求撤销本协议。
    </p>
    <p class="m-bold">
      3、根据国家法律法规变化及本软件运营需要，魔点有权不时制订、修改本协议及相关规则，变更后的协议和规则一经公布，立即取代原协议及规则并自动生效。如您不同意相关变更，应当立即停止使用魔点服务，如您继续使用魔点产品或服务进行活动，即表示您已接受经修订的协议和规则。
    </p>
    <p class="m-bold">
      4、如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人，并在您监护人的指导下阅读本协议和使用我们的服务。
    </p>
    <p>
      <span class="m-bold">5、</span
      >本协议由通过魔点网站、移动客户端及PC端魔点应用软件、硬件以及其他方式使用魔点服务的用户（以下简称“用户”或“您”）与<span
        class="m-bold"
        >杭州魔点科技有限公司</span
      >（以下合称“魔点”）共同缔结。
    </p>
    <!-- 二 -->
    <h2 class="m-mt-30">二、补充协议</h2>
    <p class="m-mt-20">
      本协议内容包括本协议正文及所有魔点已经发布或将来可能发布的隐私权政策、各项政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
    </p>
    <!-- 三 -->
    <h2 class="m-mt-30">三、服务内容</h2>
    <p class="m-mt-20 m-bold">
      1、魔点产品和服务包括魔点官网（域名moredian.com）、魔点自有平台如魔蓝平台、开放平台等、魔点自有品牌的智能硬件（诸如魔点D1/D2智能人脸识别考勤机、魔点MV1智能人脸识别访客机、魔点MS3智能人脸识别闸机柱、魔点MH2智能人脸身份自证机等）以及魔点微应用（魔点门禁、魔点访客等）等向您提供的服务（以下统称“魔点服务”，具体以魔点提供的为准）。
    </p>
    <p>
      2、魔点有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改、转移，并通过<span
        class="m-bold"
        >第十四条款</span
      >中提及的有效方式进行通知。
    </p>
    <p>
      3、使用魔点服务可能需要下载并安装相关软件，您可以直接从魔点的相关网站上获取该软件，也可以从得到魔点授权的第三方获取。<span
        class="m-bold"
      >
        如果您从未经魔点授权的第三方获取魔点服务，将视为您未获得魔点授权，魔点无法保证该服务能够正常使用，并对因此给您造成的损失不予负责。
      </span>
    </p>
    <p>
      4、经企业组织书面授权，您可以代表企业组织申请管理员账号，通过魔点审核之后可通过该管理员账号可通过魔点服务实现企业组织考勤管理、访客管理等。企业组织及授权企业管理员在对您的企业组织成员进行管理之前，应向您的企业组织成员充分说明基于企业管理需求所开通的魔点相关服务。
    </p>
    <p>
      5、如果您在使用本服务时接受邀请成为某个企业组织的成员并维持该身份时，您所在企业组织及其管理员基于企业管理需求，对您的企业组织成员身份及基于该身份的相关权益进行管理，包括但不限于加入、删除以及调整权限和限制服务内容等。您保证在遵守魔点服务规范的同时，按照企业组织的授权范围和规范使用魔点服务。
    </p>
    <!-- 四 -->
    <h2 class="m-mt-30">四、账号管理</h2>
    <p class="m-bold m-mt-20">
      1、您如果需要使用魔点服务，则您需要注册魔点账号。在您开始前注册程序使用魔点服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
    </p>
    <p>
      2、<span class="m-bold">您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相关信息</span
      >，且不得以他人身份资料进行注册。<span class="m-bold"
        >若您不进行实名注册的或您提供的注册信息不完整的，则您可能无法使用魔点服务或在使用魔点服务过程中会受到相应限制</span
      >。
    </p>
    <p>
      3、您的账户为您自行设置并由您保管，魔点任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，
      <span class="m-bold"
        >因您主动泄露账号密码或计算机及其他终端产品感染病毒、木马或因您遭受他人攻击、诈骗等行为导致的损失及后果，魔点对此不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿</span
      >。
    </p>
    <p>
      4、如发现任何未经授权您使用您账户进行魔点服务或其他可能导致您账户遭窃、遗失的情况，建议您立即通知魔点。您知晓并理解魔点对您的任何请求采取行动等均需要合理时间，且魔点应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大。
    </p>
    <p>
      5、如您注销或停止使用魔点服务时，魔点有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任，您或您代表的企业组织也有义务及时删去存储在魔点服务器上的企业信息。
    </p>
    <p>
      6、魔点有权对您提供的账号注册资料进行审查，若发现注册资料存在不准确，不真实，或含有违法、不良信息，魔点有权不予注册，并保留终止您使用魔点的权利。若您以虚假信息骗取账号注册或账号注册资料存在侵权、违法和不良信息的，魔点有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用其他机构或他人名义注册账号名称的。魔点有权注销该账号，并向政府主管部门进行报告。
    </p>
    <!-- 五 -->
    <h2 class="m-mt-30">五、服务使用规范</h2>
    <p class="m-bold m-mt-20">
      1、用户充分了解并同意，魔点仅为用户提供服务平台，您应自行对利用魔点服务从事的所有行为及结果承担责任。相应地，您应了解，使用魔点服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。
    </p>
    <p class="m-bold">
      2、您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等。
    </p>
    <p class="m-bold">
      3、除非另有说明，本协议项下的服务只能用于非商业用途。您承诺不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于包括但不限于广告及任何其它商业目的。
    </p>
    <p class="m-bold">
      4、您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为:
    </p>
    <p class="m-bold">
      4.1 上载、传送或分享含有下列内容之一的信息：
    </p>
    <p class="m-bold">
      (a) 反对宪法所确定的基本原则的；
    </p>
    <p class="m-bold">
      (b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
    </p>
    <p class="m-bold">
      (c) 损害国家荣誉和利益的；
    </p>
    <p class="m-bold">
      (d) 歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
    </p>
    <p class="m-bold">
      (e) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；
    </p>
    <p class="m-bold">
      (f) 煽动民族仇恨、民族歧视、破坏民族团结的；
    </p>
    <p class="m-bold">
      (g) 破坏国家宗教政策，宣扬邪教和封建迷信的；
    </p>
    <p class="m-bold">
      (h) 散布谣言，扰乱社会秩序，破坏社会稳定的；
    </p>
    <p class="m-bold">
      (i) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
    </p>
    <p class="m-bold">
      (j) 侮辱或者诽谤他人，侵害他人合法权利的；
    </p>
    <p class="m-bold">
      (k) 含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵或其它违反公序良俗原则的内容；
    </p>
    <p class="m-bold">
      (l) 含有中国或您所在国家适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
    </p>
    <p class="m-bold">
      4.2 冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
    </p>
    <p class="m-bold">
      4.3 伪造标题或以其他方式操控识别资料，使人误认为该内容为魔点或其关联公司所传送；
    </p>
    <p class="m-bold">
      4.4
      将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、传送或分享；
    </p>
    <p class="m-bold">
      4.5
      将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权及个人名誉、肖像权等民商事合法权利）之内容上载、传送或分享；
    </p>
    <p class="m-bold">
      4.6 跟踪或以其它方式骚扰他人, 或通过本服务向好友或其他用户发送大量信息；
    </p>
    <p class="m-bold">
      4.7
      将任何广告、推广信息、促销资料、“垃圾邮件”、“滥发信件”、“连锁信件”、“直销”或其它任何形式的劝诱资料加以上载、传送或分享；供前述目的使用的专用区域或专用功能除外；
    </p>
    <p class="m-bold">
      4.8
      因魔点服务并非为某些特定目的而设计，您不可将本服务用于包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为产品或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，魔点不承担法律责任；
    </p>
    <p class="m-bold">4.9 进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的的行为；</p>
    <p class="m-bold">
      4.10
      出于超出正常好友或用户之间内部或外部信息沟通、交流等目的（包括但不限于为发送广告、垃圾、骚扰或违法违规等信息的目的），通过自己添加或诱导他人添加等任何方式使自己与其他用户形成好友关系（好友关系包括但不限于单向好友关系和双向好友关系，下同）；
    </p>
    <p class="m-bold"
      >4.11
      违反“七条底线”即违反法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性要求的行为</p
    >
    <p class="m-bold">4.12 传播虚假中奖信息、钓鱼欺诈信息、非法或虚假理财信息等非法、诈骗或虚假信息；</p>
    <p class="m-bold">4.13 传播包含病毒、木马等的文件、程序或相应制作教程等的内容、信息；</p>
    <p class="m-bold">4.14 从事任何违反中国法律、法规、规章、政策及规范性文件的行为。</p>
    <p class="m-bold">5、您承诺不对魔点服务从事以下行为：</p>
    <p class="m-bold">
      5.1
      将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
    </p>
    <p class="m-bold"
      >5.2 干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；</p
    >
    <p class="m-bold">
      5.3
      通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
    </p>
    <p class="m-bold"
      >5.4 通过非魔点开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；</p
    >
    <p class="m-bold">5.5 自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰;</p>
    <p class="m-bold">5.6 删除魔点及其副本上关于著作权的信息；</p>
    <p class="m-bold">5.7 对魔点进行还原工程、反向工程、反向汇编、反向编译，或者以其他方式尝试发现魔点软件的源代码；</p>
    <p class="m-bold"
      >5.8 对魔点拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p
    >
    <p class="m-bold">5.9 其他可能影响、干扰魔点产品和/或本服务正常运行的行为。</p>
    <p class="m-bold">6、您承诺，使用魔点服务时您将严格遵守本协议。</p>
    <p class="m-bold">
      7、您同意并接受魔点无须时时监控您上载、传送或分享的资料及信息，但魔点有权对您使用服务的情况进行审查、监督并采取相应行动，包括但不限于删除信息、中止或终止服务，及向有关机关报告。
    </p>
    <p class="m-bold"
      >8、您承诺不以任何形式使用魔点服务侵犯魔点的商业利益，或从事任何可能对魔点造成损害或不利于魔点的行为。</p
    >
    <p class="m-bold">
      9、在魔点服务提供过程中，魔点及其关联公司自行或由其他第三方合作伙伴向您发送广告、推广或宣传信息（包括商业或非商业信息）。魔点依照法律的规定对广告商履行相关义务（包括向您提供选择关闭广告信息的功能），您应当自行判断广告信息的真实性、可靠性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，魔点不承担责任。
    </p>
    <p class="m-bold">
      10、如您申请服务管理账号，则应自行通过账号添加和管理成员，并对管理成员的行为自行承担责任，魔点根据您服务管理账号的行为对成员服务的调整亦由您承担相应后果，如因此给魔点或他人造成损失的，您应当予以赔偿。
    </p>
    <p class="m-bold">
      11、您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。魔点无法且不会对您因前述风险而导致的任何损失或损害承担责任。
    </p>
    <p class="m-bold">
      12、如果魔点发现或收到他人举报您有违反本协议约定的行为时，魔点有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于收回账号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
    </p>
    <p class="m-bold">13、上传、传播的内容与投诉处理</p>
    <p>
      13.1
      如果您上传或传播的内容违法违规或侵犯他人权利的，您同意魔点有权进行独立判断并采取删除、屏蔽或断开链接等措施，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
    </p>
    <p>
      13.2
      如您被他人投诉或您投诉他人，魔点有权将争议中相关方的主体、联系方式、投诉相关内容等必要信息提供给相关当事方或相关部门，以便及时解决投诉纠纷，保护各方合法权益。
    </p>
    <p>
      13.3
      您了解并同意，魔点有权应相关部门的要求，向其提供您提交给本平台或在使用本平台服务中存储于服务器的必要信息。如您涉嫌侵犯他人合法权益，则魔点有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供关于您的前述必要信息。必要信息包括但不限于关键组件的运行日志、运维人员的操作记录、客户操作记录等信息。
    </p>
    <p class="m-bold">14、终端安全责任</p>
    <p class="m-bold">
      14.1
      您理解并同意，本平台和本服务同大多数互联网软件、服务一样，可能会受多种因素影响（包括但不限于用户原因、网络服务质量、社会环境等）；也可能会受各种安全问题的侵扰（包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您终端的信息和数据的安全，继而影响本软件、本服务的正常使用等），因此，您应加强信息安全的保护意识，注意密码保护，以免遭受损失。
    </p>
    <p class="m-bold">14.2 您不得制作、发布、使用、传播用于窃取他人帐号、密码、个人信息等的恶意程序。</p>
    <p class="m-bold">
      14.3
      维护软件安全与正常使用是魔点和您的共同责任，魔点将按照行业标准合理审慎地采取必要技术措施保护您的终端信息和数据安全，但是您承认和同意魔点不能就此提供任何保证。
    </p>
    <h2 class="m-mt-30">六、服务费用</h2>
    <p class="m-mt-20"
      >1、魔点服务的任何免费试用或免费功能和服务不应视为魔点放弃后续收费的权利。魔点有权通过<span class="m-bold"
        >第十四条款</span
      >中的有效方式通知您收费标准。若您继续使用本服务，则需按照魔点公布的收费标准支付相应费用。所有费用需通过魔点接受的支付方式事先支付；</p
    >
    <p class="m-bold">
      2、魔点拥有制订及调整服务费的权利，具体服务费用以您使用我们服务时魔点网站或服务页面上所列的收费方式公告或您与我们达成的其他书面协议为准。您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因为收费项目或收费标准的改变、调整而要求魔点进行赔偿或补偿；
    </p>
    <p>
      3、
      <span class="m-bold"
        >用户有权自主决定是否根据魔点自行确定的收费项目（包括但不限于购买访客加油包、升级VIP等级等各类收费项目）及收费标准支付相应的费用，以获得相应的魔点服务。如您不按相应标准支付相应费用的，您将无法获得相应的魔点服务；</span
      >
    </p>
    <p>4、您应当自行支付使用魔点服务可能产生的包括但不限于上网费以及其他第三方收取的通讯费、信息费等全部费用。</p>
    <h2 class="m-mt-30">七、特别授权</h2>
    <p class="m-bold m-mt-20">
      1、当您向魔点关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，则魔点有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，魔点无须就相关确认与您核对事实，或另行征得您的同意，且魔点无须就此限制措施或公示行为向您承担任何的责任；
    </p>
    <p class="m-bold">
      2、一旦您违反本协议，或与魔点签订的其他协议的约定，魔点有权以任何方式通知魔点关联公司，要求其对您的权益采取限制措施，包括但不限于要求魔点关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。
    </p>
    <h2 class="m-mt-30">八、第三方应用及服务</h2>
    <p class="m-mt-20">
      1、魔点允许关联的第三方应用或服务参与魔点服务。<span class="m-bold"
        >魔点仅作为魔点服务提供者，除法律法规和本协议另有规定外，不对您除魔点服务外第三方服务的使用承担任何责任。</span
      >
    </p>
    <p>
      2、您通过第三方应用或服务使用魔点时，魔点可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供（包括但不限于您通过本服务跳转到的第三方提供的服务及内容等）；
    </p>
    <p class="m-bold">
      3、您在使用魔点服务相关第三方应用及服务时，除遵守本协议的约定外，还应遵守第三方应用或服务协议。
    </p>
    <h2 class="m-mt-30">九、服务中止或终止</h2>
    <p class="m-bold m-mt-20">
      1、鉴于互联网服务的特殊性，魔点有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和/或收费服务，若魔点已通过第十四条款中的有效方式发布公告，魔点无需承担责任；
    </p>
    <p class="m-bold">
      2、魔点可能定期或不定期地对提供服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成魔点服务在合理时间内中止，若魔点已通过第十四条款中的有效方式发布公告，魔点无需承担责任；
    </p>
    <p class="m-bold">3、您违反本协议约定，魔点可依据违约条款终止本协议的，并根据违约情况要求用户赔偿损失：</p>
    <p class="m-bold">4、您明示不愿继续使用魔点服务，且符合魔点终止条件的，您有权终止相关魔点服务；</p>
    <p class="m-bold">5、其他应当终止服务的情况；</p>
    <p class="m-bold">6、服务终止后，对于您过往的违约行为，魔点仍可依据本协议向您追究违约责任。</p>
    <h2 class="m-mt-30">十、隐私政策</h2>
    <p class="m-mt-20">
      1、魔点努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在魔点服务中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制、与他们签署保密协议、监控他们的操作情况等措施。魔点会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，魔点将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。
    </p>
    <p>
      2、您应当保管好终端、账号及密码，并妥善保管相关信息和内容。因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败的责任由您自行承担。
    </p>
    <p>3、其他隐私条款见《魔点隐私权政策》。</p>
    <h2 class="m-mt-30">十一、知识产权</h2>
    <p class="m-bold m-mt-20">
      1、您了解及同意，除非魔点另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于著作权、商标权、专利权、商业秘密等）及相关权利均归魔点或其关联公司所有；
    </p>
    <p class="m-bold">
      2、除非魔点书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译或以其它方式发现源代码或发表程序、内容的行为；
    </p>
    <p class="m-bold">
      3、魔点服务可能涉及第三方知识产权，第三方对您基于本协议使用魔点服务时涉及的知识产权有要求的，您应当一并遵守；
    </p>
    <p class="m-bold">4、尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任；</p>
    <p class="m-bold"
      >5、您理解并同意授权魔点在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户或合作伙伴。</p
    >
    <h2 class="m-mt-30">十二、不可抗力、免责和有限责任</h2>
    <p class="m-bold underline m-mt-20">
      1、魔点在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。
    </p>
    <p class="m-bold underline">
      2、您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务受到影响。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，魔点将努力在第一时间与相关单位配合，争取及时进行处理，但是由此给您造成的损失魔点在法律允许的范围内免责。
    </p>
    <p class="m-bold underline">
      3、魔点对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证；魔点依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，魔点并不承担相应的违约责任：
    </p>
    <p class="m-bold underline"
      >3.1
      因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因；</p
    >
    <p class="m-bold underline"
      >3.2 在魔点已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素；</p
    >
    <p class="m-bold underline">3.3 因您操作不当或通过非经我们授权或认可的方式使用我们服务的；</p>
    <p class="m-bold underline">3.4 其他魔点无法控制或合理预见的情形。</p>
    <p class="m-bold underline">
      4、鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，魔点对此不承担任何责任。
    </p>
    <p class="m-bold underline">
      5、在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，魔点对此不承担任何责任。
    </p>
    <h2 class="m-mt-30">十三、违约责任及赔偿</h2>
    <p class="m-mt-20">
      1、如果魔点发现或收到他人举报您有违反本协议约定的，魔点有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用魔点服务，限制、暂停、终止您使用部分或全部魔点服务（包括但不限于解除管理权限，限制访客预约等）、追究法律责任、并公示结果等措施；
    </p>
    <p>
      2、因您发表、上载、传输、分享的内容或使用服务功能或违反本协议、相关服务条款的规定或您侵害他人任何权利，导致或产生第三方向魔点及其关联公司主张的任何索赔、要求或损失，您应当独立承担责任；魔点及其关联公司因此遭受损失的，您也应当一并赔偿（包括但不限于经济损失、支出的律师费、公证费、诉讼费等全部费用）。若魔点首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应赔偿由此给魔点造成的全部损失（包括但不限于经济损失、支出的律师费、公证费、诉讼费等全部费用）。
    </p>
    <p class="m-bold">
      3、您充分了解并同意，您必须为自己使用本平台和/或本服务的一切行为负责，包括您所发表、上载、传输、分享的任何内容以及由此产生的任何后果。您应对使用本平台和/或本服务时接触到的内容自行加以判断，包括因对内容的正确性、完整性或实用性的依赖而产生的风险，并承担由此引起的相应风险。魔点在法律允许的范围内不对贵单位因前述风险而导致的任何损失或损害承担责任。
    </p>
    <h2 class="m-mt-30">十四、有效通知</h2>
    <p class="m-mt-20">
      1、魔点向您发出的任何通知，可采用电子邮件、页面（魔点官网、PC端应用软件或移动端微应用）公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日视为已向用户送达或生效；
    </p>
    <p>
      2、您向魔点发出的任何通知应发至魔点对外正式公布告知用户的线上或线下联系方式、通信地址、电子邮件或使用其他魔点认可的其他通知方式进行送达，联系方式如下：
    </p>
    <p>杭州魔点科技有限公司</p>
    <p>联系部门：魔点服务部</p>
    <p>地址：杭州市余杭区高教路959号未来Park园区3号楼7A</p>
    <p>邮编：310000</p>
    <p>联系电话：400-033-7675</p>
    <p>电子邮箱：marketing@moredian.com</p>
    <p>一般情况下，我们将在收到您相关联系信息后15个工作日内回复。</p>
    <h2 class="m-mt-30">十五、法律适用、管辖与其他</h2>
    <p class="m-bold m-mt-20">
      1、本协议之订立、生效、履行、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例；
    </p>
    <p class="m-bold">
      2、双方在履行本协议的过程中，如发生争议，应协商解决；协商不成时，任何一方均可向魔点所在地（浙江省杭州市余杭区）有管辖权的人民法院提起诉讼；
    </p>
    <p>3、如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力；</p>
    <p>
      4、本协议未明示授权的其他权利仍由魔点保留，您在行使这些权利时须另外取得魔点的书面许可。魔点如果未行使前述任何权利，并不构成对该权利的放弃；
    </p>
    <p>5、魔点于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权；</p>
    <p>6、本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信；</p>
    <p>
      7、魔点有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议；
    </p>
    <p>8、如果您对本协议内容有任何疑问，请拨打400-033-7675。</p>
  </div>
</template>

<script>
export default {
  name: 'CommonPrivacy',
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
#protocol-service {
  padding: 28.8px 28.8px 162px;
  color: #070721;
  p {
    font-size: 32px;
    line-height: 60px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 40px;
  }
  .m-mt-60 {
    margin-top: 60px;
  }
  .m-mt-30 {
    margin-top: 30px;
  }
  .m-mt-20 {
    margin-top: 20px;
  }
  .underline {
    text-decoration: underline;
  }
}
</style>
