
import Swiper from 'swiper/dist/js/swiper.min.js'
import { Component, Vue } from 'vue-property-decorator'
import WapConcatUs from '@/components/WapConcatUs.vue'

@Component({
  components: {
    WapConcatUs
  }
})
export default class PageOffice extends Vue {
  private options = {
    licenseKey: 'PageOffice',
    scrollOverflow: false
  }
  // 客户心声选中项
  private active = 0

  private isScroll = false

  private counselType = 4
  created() {
    if (window.screen.height <= 667) {
      this.isScroll = true
    }
  }
  mounted() {
    new Swiper('.swiper-container', {})
    new Swiper('.swiper-container1', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination1'
      }
    })
    const swiper = new Swiper('.swiper-container2', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination2'
      },
      loop: true, // 循环模式选项
      on: {
        slideChange: () => {
          this.active = swiper.realIndex
        }
      }
    })
    new Swiper('.swiper-container3', {
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination3'
      }
    })
  }
}
