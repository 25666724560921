
import { Component, Vue } from 'vue-property-decorator'

// 校验产品列表数据
interface PruductListValidate {
  img: string;
  url: string;
}
@Component
export default class PageHardware extends Vue {
  // 产品列表
  private pruductList: PruductListValidate[] = []
  // 非通过可配置搭建的老产品
  private oldProductList = [
    'y3s',
    'm3',
    'my3',
    's5',
    'mxb',
    't1',
    'd3',
    'd2',
    'g2',
    'y2',
    'mg3',
    'mog1',
    'mh2',
    'mz402',
    'mz310'
  ]

  private mounted() {
    this.init()
  }

  private init() {
    this.fetchDictDetail().then(res => {
      JSON.parse(res.data.dictValue).data.forEach(item => {
        this.pruductList.push({
          img: item.mobileImgUrl,
          url:
            this.oldProductList.indexOf(item.productEn) > -1
              ? `/product/${item.productEn}`
              : `/product?dictCode=${item.productEn}`
        })
      })
    })
  }

  /**
   * @Description: 跳转到详情页
   * @params {*}
   * @return {*}
   * @param {*} item
   */
  private handlerToDetail(item: any) {
    this.$router.push({
      path: item.url
    })
  }

  /**
   * @Description: 查询产品详情
   * @return {*}
   */
  private fetchDictDetail() {
    return this.$post('dictDetail', { dictCode: 'website_hard' })
  }
}
