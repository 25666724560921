
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Swiper from 'swiper/dist/js/swiper.min.js'
import WapTopNav from '@/components/WapTopNav.vue'
import { productionData } from '@/utils/productionData'

@Component({
  components: {
    WapTopNav
  }
})
export default class PageProduct extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  private page!: string

  private currentProduct: any = {}
  private productionData = productionData
  private productType =
    (this.$route.query || {}).dictCode || (this.$route.params || {}).production || (this.$route.query || {}).production
  private type = ''
  private position = -1
  private tabIndex = 0
  private textAlertShow = false
  private videoShow = false
  private d2Video = false
  private asData = [
    {
      itemList: [{ value: '如需购买，请拨打400-033-7675转1' }],
      show: false
    }
  ]
  private purchaseUrl = ''
  private loopSwipe = false

  private get specialHeight() {
    return `${this.currentProduct.specialData.height}%`
  }
  private get specialTop() {
    return `${this.currentProduct.specialData.top}%`
  }

  @Watch('$route.params')
  WatchRouter() {
    this.productType =
      (this.$route.params || {}).production ||
      (this.$route.query || {}).dictCode ||
      (this.$route.query || {}).production
    this.currentProduct = {}
    this.init()
    this.handleClickTab(0)
  }

  private mounted() {
    this.init()
  }

  private handelJumpPage(path: string, req: any) {
    // 跳转路由
    if (path) {
      if (path.indexOf('http') !== -1) {
        window.open(path)
      } else {
        this.$router.push({
          path: path,
          query: req || ''
        })
      }
    }
  }
  private handleClickBuy() {
    // this.asData[0].show = true
    this.textAlertShow = true
  }
  private handleClickTab(index: number) {
    if (this.tabIndex === index) {
      return false
    }
    this.tabIndex = index
    this.handleViewVideoCtrl()
    window.scrollTo(0, 0)
  }
  // private handleProductionChange(productType: any) {
  //   window.scrollTo(0, 0)
  //   this.tabIndex = 0
  //   this.productType = productType
  //   this.init()
  // }
  private handleViewVideo(index: number) {
    // 观看视频
    if (this.type !== 'd2' || this.position !== index || !this.d2Video) {
      return false
    }
    this.videoShow = true
    const videoBox: any = document.getElementById('videoBlock')
    if (videoBox) {
      videoBox.src = ''
      videoBox.src = this.currentProduct.specialData.videoUrl
      videoBox.play()
      const vm = this
      videoBox.addEventListener(
        'ended',
        function() {
          vm.videoShow = false
        },
        false
      )
    }
  }
  private handleViewVideoCtrl() {
    this.handlePauseVideo()
    this.videoShow = false
  }
  private handleCloseVideo(e: any) {
    const box: any = document.getElementById('box')
    if (!box.contains(e.target)) {
      this.handlePauseVideo()
      this.videoShow = false
    }
  }
  private handlePauseVideo() {
    const videoBox: any = document.getElementById('videoBlock')
    if (videoBox) {
      videoBox.pause()
    }
  }
  private init() {
    const { productionData, productType } = this
    this.currentProduct =
      productionData.filter(item => {
        return item.type === productType
      })[0] || {}
    this.type = this.currentProduct.type
    this.purchaseUrl = this.currentProduct.purchaseUrl
    this.d2Video = this.currentProduct.type === 'd2' && (this.currentProduct.specialData || {}).videoUrl
    this.position = (this.currentProduct.specialData || {}).position || -1
    const loopSwipe = this.currentProduct.specialData.specialType !== 'clickSwipe'
    this.loopSwipe = loopSwipe
    if (this.position > -1) {
      this.$nextTick(function() {
        let param: any = {
          loop: false,
          parallax: true,
          setWrapperSize: true,
          navigation: {
            nextEl: '.right',
            prevEl: '.left'
          }
        }
        if (loopSwipe) {
          param = {
            loop: false,
            parallax: true,
            setWrapperSize: true,
            centeredSlides: true,
            effect: 'coverflow',
            // autoplay: {
            //   delay: 3000,
            //   disableOnInteraction: false
            // },
            spaceBetween: 10,
            slidesPerView: 1.6
          }
        }
        /* eslint-disable no-new */
        new Swiper('.swipe-block', param)
      })
    }
  }
  private handleClose() {
    this.textAlertShow = false
  }
}
